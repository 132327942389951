import { produce } from 'immer';
import { ActionTypes } from './actions';
import { IAction, ICartState } from './types';

export const initialState = Object.freeze<ICartState>({
  cartData: {},
  isFetching: false,
  isFetchingError: false,
  showMobileToast: false,
  mobileToastText: '',
  cartCount: 0,
});

export default (state: ICartState = initialState, action: IAction): ICartState =>
  produce(state, (draft) => {
    const { type, payload } = action;

    switch (type) {
      case ActionTypes.SET_CART: {
        draft.cartData = payload;
        return;
      }

      case ActionTypes.FETCH_CART_PROGRESS: {
        draft.isFetching = true;
        return;
      }

      case ActionTypes.FETCH_CART_SUCCESS: {
        draft.isFetching = false;
        draft.isFetchingError = false;
        draft.cartData = payload;
        return;
      }

      case ActionTypes.FETCH_CART_FAILED: {
        draft.isFetching = false;
        draft.isFetchingError = true;
        return;
      }

      case ActionTypes.UPDATE_CART_COUNT: {
        draft.cartCount = payload;
        return;
      }

      case ActionTypes.UPDATE_CART_TOAST: {
        draft.showMobileToast = payload !== '';
        draft.mobileToastText = payload;
      }
    }
  });
