import { produce } from 'immer';
import { ActionTypes } from './actions';
import { ListingState, Action } from './types';

const ANALYTICS_EMPTY_STRING = 'EMPTY';

export const initialState = Object.freeze<ListingState>({
  products: [],
  filters: [],
  isFetching: true,
  isFetchingError: false,
  isNotFound: false,
  title: '',
  isBrand: false,
  appliedFilters: {},
  categoryId: '',
  searchTerm: '',
  currentPage: '1',
  count: 0,
  bannerImages: [],
  topCollection: [],
  metaData: {},
  tiptiles: [],
  uiMessage: '',
  footerHTML: '',
  filterGuides: {},
  categoryURL: '',
  adPlatformData: {
    pageType: '',
    pageData: '',
    positionMap: {},
    isFetching: false,
    isFetchingError: false,
    isNotFound: false,
  },
  adPlatformWidgetData: {},
  crossPlatformRedirectConfig: { enabled: false, redirectURL: '' },
  kidsFilters: null,
  isInitial: true,
  isFetchingWithFilters: false,
  filterWithNames: [],
  query_expansion_term: ANALYTICS_EMPTY_STRING,
  query_expansion_type: ANALYTICS_EMPTY_STRING,
  isOfferPage: false,
  trackingMetaData: {
    merchandising_details: {
      is_hybrid_page: false,
      has_pinned_products: false,
      has_tiptiles: false,
      has_banners: false,
    },
  },
  entityData: {
    entityCoverage: 0,
    entityType: '',
    entityValues: '',
  },
  breadcrumbs: [],
});

export default (state: ListingState = initialState, action: Action) =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case ActionTypes.SET_LISTING_ERROR: {
        draft.isFetchingError = payload.isFetchingError;
        draft.isNotFound = payload.isNotFound;
        draft.products = [];
        draft.filters = [];
        draft.isFetching = false;
        return;
      }

      case ActionTypes.SET_LISTING_DATA: {
        Object.keys(payload).forEach((i) => {
          if (payload[i] !== undefined) draft[i] = payload[i];
        });
        draft.isFetching = false;
        draft.isInitial = false;
        draft.isFetchingWithFilters = false;
        return;
      }

      case ActionTypes.UPDATE_APPLIED_FILTERS: {
        draft.appliedFilters = payload;
        draft.currentPage = '1';
        draft.isFetching = true;
        draft.isFetchingWithFilters = true;
        return;
      }
      case ActionTypes.UPDATE_PAGE_NUMBER: {
        draft.currentPage = payload;
        draft.isFetching = true;

        return;
      }
      case ActionTypes.UPDATE_CROSS_PLATFORM_REDIRECT_CONFIG: {
        draft.crossPlatformRedirectConfig = payload;

        return;
      }
    }
  });
