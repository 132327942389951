import { produce } from 'immer';
import { ActionTypes } from './actions';
import { IAction, ILandingpageState } from './types';

export const intialState = Object.freeze<ILandingpageState>({
  isFetching: false,
  isFetchingError: false,
  errorStatusCode: '',
  isNotFound: false,
  landingpageData: [],
  renderedVersion: 'v1',
});

export default (state: ILandingpageState = intialState, action: IAction): ILandingpageState =>
  produce(state, (draft) => {
    const { type, payload } = action;

    switch (type) {
      case ActionTypes.FETCH_LANDINGPAGE_DATA_PROGRESS: {
        draft.isFetching = true;
        return;
      }
      case ActionTypes.FETCH_LANDINGPAGE_DATA_SUCCESS: {
        draft.isFetching = false;
        draft.isFetchingError = false;
        draft.errorStatusCode = '';
        draft.isNotFound = false;
        draft.landingpageData = payload.data;
        draft.renderedVersion = payload.renderedVersion;
        return;
      }
      case ActionTypes.FETCH_LANDINGPAGE_DATA_FAILED: {
        const { statusCode } = payload;

        draft.errorStatusCode = statusCode;
        draft.isFetching = false;
        draft.isFetchingError = true;
        draft.isNotFound = false;
        return;
      }
      case ActionTypes.FETCH_LANDINGPAGE_DATA_NOT_FAILED: {
        draft.isFetching = false;
        draft.isFetchingError = false;
        draft.isNotFound = true;
        return;
      }
    }
  });
