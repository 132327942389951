export const ActionTypes = {
  SET_CART: 'cart/update_cart',
  FETCH_CART_PROGRESS: 'cart/fetch_cart_progress',
  FETCH_CART_SUCCESS: 'cart/fetch_cart_success',
  FETCH_CART_FAILED: 'cart/fetch_cart_failed',
  UPDATE_CART_COUNT: 'cart/update_cart_count',
  UPDATE_CART_TOAST: 'cart/update_cart_toast',
};

export const setCartData = (data: any) => ({
  type: ActionTypes.SET_CART,
  payload: data,
});

export const setCartToast = (data: string) => ({
  type: ActionTypes.UPDATE_CART_TOAST,
  payload: data,
});
