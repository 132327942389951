export const ActionTypes = {
  FETCH_LANDINGPAGE_DATA_PROGRESS: 'landingpage/fetch_landingpage_progress',
  FETCH_LANDINGPAGE_DATA_SUCCESS: 'landingpage/fetch_landingpage_success',
  FETCH_LANDINGPAGE_DATA_FAILED: 'landingpage/fetch_landingpage_failed',
  FETCH_LANDINGPAGE_DATA_NOT_FAILED: 'landingpage/fetch_landingpage_data_not_found',
};

export const notFound = () => ({
  type: ActionTypes.FETCH_LANDINGPAGE_DATA_NOT_FAILED,
});
