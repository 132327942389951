// types
import { ICrossPlatformRedirectConfig } from './types';

export const ActionTypes = {
  FETCH_LISTING_INITIAL_DATA_PROGRESS: 'listing/fetch_listing_initial_data_progress',
  FETCH_LISTING_INITIAL_DATA_SUCCESS: 'listing/fetch_listing_initial_data_success',
  FETCH_LISTING_INITIAL_DATA_FAILED: 'listing/fetch_listing_initial_data_failed',
  FETCH_LISTING_DATA_PROGRESS: 'listing/fetch_listing_data_progress',
  FETCH_LISTING_DATA_SUCCESS: 'listing/fetch_listing_data_success',
  FETCH_LISTING_DATA_NOT_FOUND: 'listing/fetch_listing_data_not_found',
  FETCH_LISTING_DATA_FAILED: 'listing/fetch_listing_data_failed',
  SET_FILTERS: 'listing/set_filters',
  SET_CATEGORY_ID: 'listing/set_category_id',
  SET_SEARCH_TERM: 'listing/set_search_term',
  APPLY_FILTERS: 'listing/apply_filters',
  UPDATE_CURRENT_PAGE: 'listing/update_current_page',
  UPDATE_SORT_BY: 'listing/update_sort_by',
  RESET: 'listing/reset',
  FETCH_ADPLATFORM_WIDGETS_PROGRESS: 'listing/fetch_adplatform_widgets_progess',
  FETCH_ADPLATFORM_WIDGETS_SUCCESS: 'listing/fetch_adplatform_widgets_success',
  FETCH_ADPLATFORM_WIDGETS_FAILED: 'listing/fetch_adplatform_widgets_failed',
  FETCH_ADPLATFORM_WIDGETS_NOT_FOUND: 'listing/fetch_adplatform_widgets_not_found',
  FETCH_ADPLATFORM_WIDGETS_CLEAR: 'listing/fetch_adplatform_widgets_clear',
  TRIGGER_CROSS_PLATFORM_REDIRECT: 'listing/trigger_cross_platform_redirection',
  RESET_CROSS_PLATFORM_REDIRECT: 'listing/reset_cross_platform_redirection',
  SET_BEST_PRICE: 'listing/best_price',
};

export const applyFilters = (filterKey: string, values: string | number | string[]) => ({
  type: ActionTypes.APPLY_FILTERS,
  payload: { filterKey, values },
});

export const applySorting = (selectedValue: string) => ({
  type: ActionTypes.UPDATE_SORT_BY,
  payload: selectedValue,
});

export const setFilters = (filters: any) => ({
  type: ActionTypes.SET_FILTERS,
  payload: filters,
});

export const updateCurrentPage = (pageNumber: number) => ({
  type: ActionTypes.UPDATE_CURRENT_PAGE,
  payload: pageNumber,
});

export const setCategoryId = (categoryId: string) => ({
  type: ActionTypes.SET_CATEGORY_ID,
  payload: categoryId,
});

export const setSearchTerm = (searchTerm: string) => ({
  type: ActionTypes.SET_SEARCH_TERM,
  payload: searchTerm,
});

export const reset = () => ({
  type: ActionTypes.RESET,
});

export const setListingData = (payload: any) => ({
  type: ActionTypes.FETCH_LISTING_INITIAL_DATA_SUCCESS,
  payload,
});

export const setCrossPlatformRedirectionConfig = (payload: ICrossPlatformRedirectConfig) => ({
  type: ActionTypes.TRIGGER_CROSS_PLATFORM_REDIRECT,
  payload,
});

export const resetCrossPlatformConfig = () => ({
  type: ActionTypes.RESET_CROSS_PLATFORM_REDIRECT,
});

export const setIsNotFound = () => ({
  type: ActionTypes.FETCH_LISTING_DATA_NOT_FOUND,
});

export const setBestPrice = (payload: any) => ({
  type: ActionTypes.SET_BEST_PRICE,
  payload,
});
