// helpers
import { transformDeliveryText, transformDeliveryTextV1 } from 'shared/store/transformer/transform';
import logger from 'shared/logger';

// types
import { ServiceabilityDataObj } from 'shared/types/cart';
import get from 'lodash/get';
import set from 'lodash/set';
import isEmpty from 'lodash/isEmpty';

interface TableCellData {
  value: string;
  dataType: string;
  labelType?: string;
  label?: string;
  conversion?: boolean;
}
interface TransformedData {
  [key: string]: TableCellData[][];
}

interface LabelsAvailable {
  [key: string]: { type: string; count: number; value: string };
}

const LABELS = {
  soldOut: {
    type: 'soldOut',
    text: 'Sold Out',
  },
  stockRemaining: { type: 'stockRemaining', text: ' left' },
};

export const minDeliveryOrShippingDay = (
  data: ServiceabilityDataObj,
  freeDeliveryAvailable: boolean
) => {
  let skuWithMinShippingDays = '';
  let skuWithMinDeliveryDays = '';
  let minDeliveryDays = Number.MAX_SAFE_INTEGER;
  let minShippingDays = Number.MAX_SAFE_INTEGER;

  for (const key in data) {
    if (data[key].o2dTat !== null && minDeliveryDays > data[key].o2dTat) {
      skuWithMinDeliveryDays = key;
      minDeliveryDays = data[key].o2dTat;
    }

    if (data[key].o2sTat !== null && minShippingDays > data[key].o2sTat) {
      skuWithMinShippingDays = key;
      minShippingDays = data[key].o2sTat;
    }
  }

  // since minDeliveryDays === Number.MAX_SAFE_INTEGER that means, we have
  // delivery date as null because user do not have saved pincode
  // so will return min shipping date
  if (minDeliveryDays === Number.MAX_SAFE_INTEGER) {
    return transformDeliveryText(data, skuWithMinShippingDays, freeDeliveryAvailable);
  }

  // return min shipping date
  return transformDeliveryText(data, skuWithMinDeliveryDays, freeDeliveryAvailable);
};

export const minDeliveryOrShippingDayV1 = (
  data: ServiceabilityDataObj,
  freeDeliveryAvailable: boolean
) => {
  let skuWithMinShippingDays = '';
  let skuWithMinDeliveryDays = '';
  let minDeliveryDays = Number.MAX_SAFE_INTEGER;
  let minShippingDays = Number.MAX_SAFE_INTEGER;

  for (const key in data) {
    if (data[key].o2dTat !== null && minDeliveryDays > data[key].o2dTat) {
      skuWithMinDeliveryDays = key;
      minDeliveryDays = data[key].o2dTat;
    }

    if (data[key].o2sTat !== null && minShippingDays > data[key].o2sTat) {
      skuWithMinShippingDays = key;
      minShippingDays = data[key].o2sTat;
    }
  }

  // since minDeliveryDays === Number.MAX_SAFE_INTEGER that means, we have
  // delivery date as null because user do not have saved pincode
  // so will return min shipping date
  if (minDeliveryDays === Number.MAX_SAFE_INTEGER) {
    return transformDeliveryTextV1(data, skuWithMinShippingDays, freeDeliveryAvailable);
  }

  // return min shipping date
  return transformDeliveryTextV1(data, skuWithMinDeliveryDays, freeDeliveryAvailable);
};

export function getUpdatedCustomisationData({ customisationData, index, values = {} }) {
  const previousSectionObject = get(customisationData, `sections[${index}]`);
  return set(customisationData, `sections[${index}]`, {
    ...previousSectionObject,
    ...values,
  });
}

/**
 * Creates a header row for the table using the column labels from the meta object of sizeOptions.
 * The first element of the row will always be 'size', followed by the remaining column labels.
 *
 * @param columnOrder - An array specifying the order of columns.
 * @param columnOrderObject - An object storing columns by ID for easy access.
 * @returns An array representing the header row with each element containing the value, dataType, key, and conversion.
 */
const createHeaderRow = (
  columnOrder: string[],
  columnOrderObject: { [key: string]: ProductDetail.Column }
): {
  value: string;
  dataType: string;
  labelType?: string;
  label?: string;
  conversion?: boolean;
  key?: string;
}[] => {
  return [
    { value: 'size', dataType: 'header', key: 'size', conversion: false },
    ...columnOrder.map((column) => ({
      key: column,
      value: columnOrderObject[column]?.label,
      conversion: columnOrderObject[column]?.conversion || false,
      dataType: 'header',
    })),
  ];
};

/**
 * Generates label and label type based on the provided size option data.
 * @param sizeOption - ProductDetail.ISizeOption object
 * @returns Object with label and labelType properties
 */
function getLabelData(sizeOption: ProductDetail.ISizeOption): {
  label: string | null;
  labelType: string | null;
} {
  const labelType =
    sizeOption.isOutOfStock === 1
      ? LABELS.soldOut.type
      : sizeOption?.stock_remaining && sizeOption.stock_remaining > 0
      ? LABELS.stockRemaining.type
      : null;

  const label =
    labelType === LABELS.soldOut.type
      ? LABELS.soldOut.text
      : labelType === LABELS.stockRemaining.type
      ? `${sizeOption.stock_remaining} ${LABELS.stockRemaining.text}`
      : null;

  return { label, labelType };
}

/**
 * Transformation function to convert sizeOptions data into a format suitable for rendering a table.
 *
 * @param sizeOptions - The size options data to be transformed.
 * @returns An object containing the transformed table data, column information, and other relevant details.
 */

const generateSizeGuideData = ({ sizeOptions }: { sizeOptions: ProductDetail.SizeOptions }) => {
  try {
    // Destructure relevant data from sizeOptions
    const { options = [], meta = {} } = sizeOptions;

    if (isEmpty(meta) || options.length === 0) {
      return {};
    }

    const { columns } = meta as { columns: ProductDetail.Column[] };

    // Initialize an object to store the transformed data for different units
    const transformedData: TransformedData = {
      in: [],
      cm: [],
    };

    // Create an array with the order of columns
    const columnOrder: string[] = [];

    // Object to store label information (e.g., 'stockRemaining', 'soldOut')
    const labelsAvailable: LabelsAvailable = {
      stockRemaining: {
        type: LABELS.stockRemaining.type,
        count: 0,
        value: LABELS.stockRemaining.text,
      },
      soldOut: { type: LABELS.soldOut.type, count: 0, value: LABELS.soldOut.text },
    };

    // Object to store columns by ID for easy access
    const columnOrderObject: { [key: string]: ProductDetail.Column } = {};
    columns.forEach((column) => {
      columnOrderObject[column.id] = column;
      columnOrder.push(column.id);
    });

    const headerRowData = createHeaderRow(columnOrder, columnOrderObject);

    // check is atlest one column contains conversion
    const columnContainsConversion =
      (columnOrder.filter((column) => columnOrderObject[column]?.conversion === true) || [])
        .length > 0;

    // Push header rows for 'in' and 'cm' units
    transformedData.cm.push(headerRowData);
    transformedData.in.push(headerRowData);

    // Step 2: Create Table Data (Rows)
    options.forEach((sizeOption: ProductDetail.ISizeOption) => {
      const sizeDataRowIn: TableCellData[] = [];
      const sizeDataRowCm: TableCellData[] = [];

      const { label, labelType } = getLabelData(sizeOption);

      if (labelType !== null) {
        labelsAvailable[labelType].count += 1;
      }

      // Add size name as the first column
      // add labrl only if its value is not null
      const firstColumnValue = {
        value: sizeOption?.sizeName,
        dataType: 'sizeName',
        ...(labelType !== null && { labelType: labelType }),
        ...(label !== null && { label: label }),
      };

      sizeDataRowIn.push(firstColumnValue);
      sizeDataRowCm.push(firstColumnValue);

      // Add data for each column based on columnOrder in in and cm units
      columnOrder.forEach((columnName) => {
        const columnData = get(sizeOption, `size_data.${columnName}`, {}) || {};

        if (isEmpty(columnData) === false) {
          sizeDataRowIn.push({
            value: columnData ? columnData.inch : '_',
            dataType: 'data',
            label: undefined,
          });
          sizeDataRowCm.push({ value: columnData ? columnData.cm : '_', dataType: 'data' });
        }
      });

      transformedData.in.push(sizeDataRowIn);
      transformedData.cm.push(sizeDataRowCm);
    });

    return {
      title: sizeOptions?.title,
      howToMeasureImage: sizeOptions?.meta?.how_to_measure,
      transformedTableData: transformedData,
      columnKeyOrders: columnOrder,
      columnOrderObject,
      avilableLabels: labelsAvailable,
      unitText: sizeOptions?.meta?.unit_text,
      columnContainsConversion: columnContainsConversion,
    };
  } catch (err) {
    return {};
  }
};

/**
 * Retrieves the transformed size guide data based on the provided size options and product information.
 *
 * @param sizeOptions - The size options data to be transformed.
 * @param isOneSize - A boolean indicating whether the product has only one size.
 * @param OneSizeData - The product data for the one size option.
 * @returns The transformed size guide data, or null if an error occurs.
 */
export const getTransformedSizeGuideData = ({
  sizeOptions,
  isOneSize,
  skuData,
}: {
  sizeOptions: ProductDetail.SizeOptions;
  isOneSize: boolean;
  skuData: ProductDetail.IProduct;
}) => {
  try {
    // If the product has multiple sizes
    if (!isOneSize) {
      return generateSizeGuideData({ sizeOptions });
    }

    // if the product has only one size
    const { sizeOptions: sizeOptionsData, ...rest } = skuData;

    // creating sizeoptions for single product
    const sizeOptionsForSingleProduct = {
      ...sizeOptionsData,
      options: [rest],
    };

    return generateSizeGuideData({ sizeOptions: sizeOptionsForSingleProduct });
  } catch (err) {
    logger.error({
      message: 'SIZE_GUIDE_TRANSFORMATION_ERROR',
      METHOD_NAME: 'getTransformedSizeGuideData',
      info: {
        error: err,
        isOneSize,
        productId: skuData?.id,
      },
    });
  }
};
