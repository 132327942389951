// helpers
import Storage from 'shared/helpers/storage';

const USER_DATA_KEY = 'USER_STORE';

const DEFAULT_USER_DATA = {};

// user data object
let USER_DATA = DEFAULT_USER_DATA;

export const initializeUserData = () => {
  /**
   * read data from localstorage if available and parse it
   * and store it in JS in memory variable
   **/

  USER_DATA = Storage.getItem(USER_DATA_KEY, DEFAULT_USER_DATA) || DEFAULT_USER_DATA;
};

export const setUserData = (data: Record<string, any>) => {
  // update local in memory userData
  USER_DATA = data;
  // update localStorage with stringified data
  Storage.setItem(USER_DATA_KEY, data);
};

export const getUserData = (): Record<string, any> => {
  /** We are spreading each time to avoid object mutation */
  // return in memory user data
  return { ...USER_DATA };
};
