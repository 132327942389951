// helpers
import Storage from 'shared/helpers/storage';

// defs
import { AppState } from 'shared/store/app/types';

const NYKAA_CID_KEY = 'CID';

export const cacheCustomerData = (customerData: AppState['user']['customerData']) => {
  Storage.setItem(NYKAA_CID_KEY, customerData);
};

export const getCachedCustomerData = (): AppState['user']['customerData'] | undefined => {
  const savedData = Storage.getItem(NYKAA_CID_KEY);

  if (!savedData) {
    return undefined;
  }

  return savedData as AppState['user']['customerData'];
};
