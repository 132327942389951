// libs
import get from 'lodash/get';

// helpers
import { setFirstTimeUser } from 'shared/helpers/apiExperiment/userSegmentation';
import { setApiExperiment } from 'shared/helpers/apiExperiment';
import { setApiTimeoutValue } from 'shared/helpers/apiTimeout';
import { isMobileBrowser } from 'shared/helpers/dom';
import { setAppInitPath } from 'shared/helpers/routes';
import { setDeviceType } from 'shared/helpers/app';
import { initializeUserData } from 'shared/components/UserData/helpers';
import { getIsExpEnabledOnInternationalUser } from './helpers';

export const setGlobals = (state: any) => {
  // is analytics enabled for the user
  const isExptEnabled = getIsExpEnabledOnInternationalUser(state);

  if (isExptEnabled) {
    // set time for user Grouping and experimenting
    setFirstTimeUser();

    // set Api Experimentation
    setApiExperiment(state);
  }

  // set API Timeout for the application
  const apiTimeoutValue = get(state, 'remoteConfigs.apiTimeout');
  setApiTimeoutValue(apiTimeoutValue);

  // setting initial app path
  setAppInitPath(window.location.pathname);

  // using browser agent to identify Mweb or Dweb
  setDeviceType(isMobileBrowser() ? 'MSITE' : 'WEBSITE');

  // initialize user data
  initializeUserData();
};
