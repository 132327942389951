// helpers
import { getCookie, setCookie } from 'shared/helpers/utils';

const RUM_key = 'rum';
const RUM_TIMESTAMP_KEY = 'tm_stmp';
const MAX_TIME_FOR_BUCKET = 30;

// generate unique user identifier
const generateUserIdentifier = () => Math.floor(Math.random() * 100 + 1);

// set user Identifier in the cookie
export const setUserIdentifier = () =>
  setCookie(RUM_key, generateUserIdentifier(), MAX_TIME_FOR_BUCKET);

// get the user aquisition time
export const getUserLandingTimestamp = () => Number(getCookie(RUM_TIMESTAMP_KEY));

// set time as identifier for the first time user,
// later this time will be taken as reference for user grouping
export const setFirstTimeUser = () => {
  if (!getUserLandingTimestamp()) {
    setCookie(RUM_TIMESTAMP_KEY, new Date().getTime(), 999);
  }
};

// key for every experiment
export const getExperimentIdentifier = (experimentName: string) => {
  const identifierKey = `${RUM_key}_${experimentName}`;
  let identifierNumber = Number(getCookie(identifierKey));

  if (!identifierNumber) {
    identifierNumber = generateUserIdentifier();
    setCookie(identifierKey, identifierNumber, MAX_TIME_FOR_BUCKET);
  }

  return identifierNumber;
};
