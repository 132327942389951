import { createContext, useContext } from 'react';
import { BREAKPOINTS } from 'shared/styles/base';

interface IResponsiveContext {
  deviceWidth: number;
  isDesktop: boolean;
}

const deviceWidth = BREAKPOINTS.MOBILE;

const initialValue: IResponsiveContext = {
  deviceWidth,
  isDesktop: deviceWidth > BREAKPOINTS.DESKTOP,
};

const ResponsiveContext = createContext(initialValue);

export const useResponsiveContext = () => useContext(ResponsiveContext);

export default ResponsiveContext;
