// libs
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

// helpers
import { log } from 'shared/helpers/newRelic';
import { getExperimentIdentifier, getUserLandingTimestamp } from './userSegmentation';
import { clearSessionInfo, setSessionInfo } from './storage';
import { browserResponsiveMode, RENDER_MODE } from 'shared/components/ResponsiveLoader';

// def
import {
  ISingleExperiment,
  IVariantProps,
  IAudienceBehavior,
  IParamExperimentProps,
} from './types';

const DEFAULT_AUDIENCE_BEHAVIOR: IAudienceBehavior = {};

// should calculate on the basis of maximum and minimum time, the user has spent from the start
export const isValidAudienceBehavior = (audienceBehavior = DEFAULT_AUDIENCE_BEHAVIOR) => {
  if (isEmpty(audienceBehavior)) {
    return true;
  }

  const inMilliseconds = new Date().getTime() - getUserLandingTimestamp();
  const timeSincefirstVisit = Math.floor(inMilliseconds / (1000 * 60 * 60 * 24));
  const shouldBeGtThan = Number(audienceBehavior.greaterThanDays);
  const shouldBeLtThan = Number(audienceBehavior.lesserThanDays);
  let allowed = true;

  if (Number.isNaN(shouldBeGtThan) === false && timeSincefirstVisit < shouldBeGtThan) {
    allowed = false;
  }

  if (Number.isNaN(shouldBeLtThan) === false && timeSincefirstVisit > shouldBeLtThan) {
    allowed = false;
  }

  return allowed;
};

const canRenderExperiment = (experiment: ISingleExperiment) => {
  let enabled = isValidAudienceBehavior(experiment.audienceBehavior);

  const { deviceType } = browserResponsiveMode();

  const isMobile = deviceType === RENDER_MODE.MOBILE;

  if (isMobile && experiment.enableForMobile === false) {
    enabled = false;
  } else if (isMobile === false && experiment.enableForDesktop === false) {
    enabled = false;
  }

  return enabled;
};

const compare = (a: IVariantProps, b: IVariantProps) => {
  if (a.weightage < b.weightage) {
    return -1;
  }
  if (a.weightage > b.weightage) {
    return 1;
  }
  return 0;
};

export const setApiExperiment = (state: any) => {
  try {
    const data: ISingleExperiment[] = get(state, 'remoteConfigs.AB.api', '[]');
    let experimentsRunning: IParamExperimentProps = {};

    // reset the existing experiment information from storage
    clearSessionInfo();

    // render all the experiments
    data.forEach((experiment) => {
      const identifier = getExperimentIdentifier(experiment.name);
      let totalWeight = 0;

      // if experiment is disabled, or the name of experiment is empty, do not run the experiment.
      if (canRenderExperiment(experiment) === false || !experiment.name) {
        return;
      }

      const options = experiment.variants.sort(compare).map((variant) => {
        totalWeight += variant.weightage;
        return { ...variant, range: totalWeight };
      });

      for (let i = 0; i < options.length; i++) {
        let rangeToCompare = options[i].range;

        // edge case: incase sum of variant weights is more than 100 than convert weights in percentage
        if (totalWeight > 100) {
          rangeToCompare = (options[i].range / totalWeight) * 100;
        }

        if (identifier <= rangeToCompare) {
          window.api_ab = { ...window.api_ab, [experiment.name]: options[i].name };
          experimentsRunning = {
            ...experimentsRunning,
            [experiment.srcUrl]: {
              targetUrl: options[i].targetUrl || '',
              params: options[i].params || {},
            },
          };
          return;
        }
      }
    });

    // set the value of experiment in once
    setSessionInfo(experimentsRunning);
  } catch (err) {
    log({
      errTitle: 'Error in setApiExperiment',
      err: err,
    });
  }
};
