//helpers
import { getDomainConfig } from 'shared/domainConfig/helpers';

interface DomainConfig {
  [key: string]: string;
}

export enum DEVICE_TYPE {
  DESKTOP = 'WEBSITE',
  MOBILE = 'MSITE',
}

let deviceType: DEVICE_TYPE = DEVICE_TYPE.MOBILE;

export const setDeviceType = (newDeviceType: DEVICE_TYPE) => {
  deviceType = newDeviceType;
};

export const getDeviceType = () => deviceType;

const { API, API_OLD } = getDomainConfig();
const DOMAIN_CONFIG: DomainConfig = {
  NYKAA_FASHION: 'NYKAA_FASHION',
  FOOT_LOCKER: 'FOOT_LOCKER',
};

export const getDomain = () => DOMAIN_CONFIG[API.DOMAIN_PARAM];

export const getOldDomain = () => API_OLD.DOMAIN_PARAM;
