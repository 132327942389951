import { BRAND_LOGO } from 'shared/constants';

export default JSON.stringify({
  '@context': 'http://schema.org',
  '@type': 'Corporation',
  name: 'Nykaa Fashion',
  legalName: 'Nykaa Fashion Limited',
  description:
    'Nykaafashion.com is an online fashion and lifestyle website that offers apparel, accessories, footwear, jewellery. There are over 2,500 brands and 500,000 styles.',
  url: 'https://www.nykaafashion.com/',
  logo: BRAND_LOGO,
  foundingDate: '2018',
  foundingLocation: 'Mumbai',
  founders: [
    {
      '@type': 'Person',
      name: 'Adwaita Nayar',
    },
  ],
  address: {
    '@type': 'PostalAddress',
    streetAddress:
      '#A2, 4th Floor, Cnergy IT Park, Old Standard Mill Compound, Appasaheb Marathe Marg, Prabhadevi',
    addressLocality: 'Prabhadevi',
    addressRegion: 'Mumbai',
    postalCode: '400025',
    addressCountry: 'India',
  },
  contactPoint: {
    '@type': 'ContactPoint',
    contactType: 'customer support',
    telephone: '1800-266-3333',
    email: 'support@nykaafashion.com',
    hoursAvailable: [
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: 'http://schema.org/Monday',
        opens: '10:00:00',
        closes: '22:00:00',
      },
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: 'http://schema.org/Tuesday',
        opens: '10:00:00',
        closes: '22:00:00',
      },
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: 'http://schema.org/Wednesday',
        opens: '10:00:00',
        closes: '22:00:00',
      },
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: 'http://schema.org/Thursday',
        opens: '10:00:00',
        closes: '22:00:00',
      },
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: 'http://schema.org/Friday',
        opens: '10:00:00',
        closes: '22:00:00',
      },
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: 'http://schema.org/Saturday',
        opens: '10:00:00',
        closes: '22:00:00',
      },
      {
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: 'http://schema.org/Sunday',
        opens: '10:00:00',
        closes: '19:00:00',
      },
    ],
  },
  sameAs: [
    'https://www.instagram.com/nykaafashion/',
    'https://www.facebook.com/NykaaFashion',
    'https://www.youtube.com/channel/UCatukUEsfqjQDs8GHJ7zhkQ',
    'https://twitter.com/nykaafashion',
    'https://in.pinterest.com/nykaafashion/',
    'https://www.crunchbase.com/organization/nykaa-fashion',
  ],
});
