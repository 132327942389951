import defaultRemoteConfig from 'server/config/remoteConfig';

export type RemoteConfigState = typeof defaultRemoteConfig;

export const ActionTypes = {
  FETCH_DETAILS_PROGRESS: 'hybrid_pdp/fetch_details_progress',
  FETCH_DETAILS_SUCCESS: 'hybrid_pdp/fetch_details_success',
  FETCH_DETAILS_FAILURE: 'hybrid_pdp/fetch_details_failure',
  FETCH_WIDGETS_PROGRESS: 'hybrid_pdp/fetch_widgets_progress',
  FETCH_WIDGETS_SUCCESS: 'hybrid_pdp/fetch_widgets_success',
  FETCH_WIDGETS_FAILURE: 'hybrid_pdp/fetch_widgets_failure',
  UPDATE_WIDGETS: 'hybrid_pdp/update_widgets',
};

export interface Action {
  type: string;
  payload: any;
}

export interface TransformedProductInfo {
  deliveryInfo: string;
  images: string[];
  priceInfo: { discount: number; discountedPrice: number; originalPrice: number };
  ratingsInfo: {
    rating: string;
    ratingsCount: string;
  };
  sizeGuideURL: string;
  sizeOptions: {
    options: any[];
    title: string;
  };
  subTitle: string;
  tags: string[];
  title: string;
  type: string;
  actionURL: string;
  inStock: boolean;
  id: string;
  sku: string;
}

export interface TransformedWidgetInfo {
  fetchMoreProducts: boolean;
  products: any[];
  pageNumber: number;
}

export interface HybridPDPState {
  productID: string;
  product: {
    fetching: boolean;
    statusCode: string;
    details: TransformedProductInfo;
  };
  widgets: {
    fetching: boolean;
    statusCode: string;
    details: TransformedWidgetInfo;
  };
}

export interface GetWidgetDetailsProps {
  productID: string;
  widgetType: string;
  widgetAlgo: string;
}
