// constants
import { BREAKPOINTS, DWEB_MAX_WIDTH } from 'shared/styles/base';
import { RENDER_MODE } from 'shared/constants';

// local js variable to be consumed by js function and react components alike
let renderMode: RENDER_MODE = RENDER_MODE.MOBILE;

interface ResponsiveModeOut {
  deviceType: RENDER_MODE;
  deviceWidth: number;
}

export const serverResponsiveMode = (userAgent = ''): ResponsiveModeOut => {
  let deviceType: RENDER_MODE, deviceWidth: number;

  if (userAgent.match(/mobile|phone|ipad/i)) {
    deviceType = RENDER_MODE.MOBILE;
    deviceWidth = BREAKPOINTS.MOBILE;
    renderMode = RENDER_MODE.MOBILE;
  } else {
    deviceType = RENDER_MODE.DESKTOP;
    deviceWidth = deviceWidth = DWEB_MAX_WIDTH;
    renderMode = RENDER_MODE.DESKTOP;
  }

  return { deviceType, deviceWidth };
};

export const getDeviceType = () => {
  return renderMode;
};

export const browserResponsiveMode = (): ResponsiveModeOut => {
  const deviceWidth: number = window.innerWidth;

  let deviceType = renderMode;

  if (deviceWidth < BREAKPOINTS.DESKTOP) {
    renderMode = RENDER_MODE.MOBILE;
    deviceType = RENDER_MODE.MOBILE;
  } else {
    renderMode = RENDER_MODE.DESKTOP;
    deviceType = RENDER_MODE.DESKTOP;
  }

  return { deviceType, deviceWidth };
};

export const canShow = (breakpoint: number, deviceWidth: number, up: boolean, down: boolean) => {
  if (up && deviceWidth > breakpoint) {
    return true;
  }

  if (down && deviceWidth <= breakpoint) {
    return true;
  }

  if (breakpoint === deviceWidth) {
    return true;
  }

  return false;
};
