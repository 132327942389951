import React, { useState, useContext, useEffect, memo } from 'react';
import ResponsiveContext from './ResponsiveContext';
import { canShow } from './helpers';

interface IResponseLoaderProps {
  breakpoint: number;
  children: React.ReactNode;
  up?: boolean;
  down?: boolean;
}

function ResponsiveLoader({
  breakpoint,
  children,
  up = false,
  down = false,
}: IResponseLoaderProps) {
  const { deviceWidth } = useContext(ResponsiveContext);
  const [show, setShow] = useState(canShow(breakpoint, deviceWidth, up, down));

  useEffect(() => {
    const rAFTimer = window.requestAnimationFrame(() => {
      setShow(canShow(breakpoint, deviceWidth, up, down));
    });

    return () => window.cancelAnimationFrame(rAFTimer);
  }, [deviceWidth, breakpoint, setShow, up, down]);

  if (show === false) {
    return null;
  }

  return <>{children}</>;
}

export default memo(ResponsiveLoader);
