let hasLocalStorage = 'localStorage' in global;

let ls, testKey;

if (hasLocalStorage) {
  testKey = 'nykka.test';
  try {
    // Access to global `localStorage` property must be guarded as it
    // fails under iOS private session mode.
    ls = window.localStorage;
    ls.setItem(testKey, 'foo');
    ls.removeItem(testKey);
  } catch (e) {
    hasLocalStorage = false;
  }
}

const CLIENT_STORAGE_PREFIX = 'NYKAA_';

if (!hasLocalStorage && typeof window !== 'undefined') {
  console.warn('localStorage not found');
}

export const hasStorageSupport = hasLocalStorage;

export class Storage {
  private localStorage: typeof window.localStorage | undefined;

  public constructor() {
    if (hasStorageSupport) {
      this.localStorage = window.localStorage;
    }
  }

  private getItemName(name: string) {
    return CLIENT_STORAGE_PREFIX + name;
  }

  public getItem<T>(
    name: string,
    ifNotFound: T | null = null,
    returnAboslute: boolean | undefined = false
  ): T | null {
    if (hasLocalStorage === false || this.localStorage === undefined) {
      return ifNotFound;
    }

    const value = this.localStorage.getItem(this.getItemName(name)); // will return a string

    // @ts-ignore
    let finalValue: T = value;
    try {
      if (value !== null) {
        finalValue = JSON.parse(value);
      }
      if (returnAboslute) {
        return finalValue;
      }
    } catch (e) {
      /* do nothing */
    }

    return finalValue || ifNotFound;
  }

  public setItem<T>(name: string, value: T) {
    if (hasLocalStorage === false || this.localStorage === undefined) {
      return false;
    }

    this.localStorage.setItem(this.getItemName(name), JSON.stringify(value));

    return true;
  }

  public removeItem(name: string) {
    if (hasLocalStorage === false || this.localStorage === undefined) {
      return;
    }

    this.localStorage.removeItem(this.getItemName(name));
  }

  public clear() {
    if (hasLocalStorage && this.localStorage !== undefined) {
      this.localStorage.clear();
    }
  }

  public get length() {
    if (hasLocalStorage === false || this.localStorage === undefined) {
      return 0;
    }

    return this.localStorage.length;
  }
}

const storage = new Storage();

export default storage;
