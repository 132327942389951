import { produce } from 'immer';
import { ActionTypes } from './actions';
import { IAction, TopBannerState } from './types';
import { BANNER_REMOTE_CONFIG_TYPE } from 'shared/components/TopBanner/types';

export const initialState = Object.freeze<TopBannerState>({
  plpTopBanner: {
    isFetching: false,
    isSuccess: false,
    isFetchingError: false,
    isNotFound: false,
    widgetData: [],
  },
  pdpTopBanner: {
    isFetching: true,
    isSuccess: false,
    isFetchingError: false,
    isNotFound: false,
    widgetData: [],
  },
  wishlistTopBanner: {
    isFetching: false,
    isSuccess: false,
    isFetchingError: false,
    isNotFound: false,
    widgetData: [],
  },
  couponLoggedInTopBanner: {
    isFetching: false,
    isSuccess: false,
    isFetchingError: false,
    isNotFound: false,
    widgetData: [],
  },
  hybridPDPTopBanner: {
    isFetching: false,
    isSuccess: false,
    isFetchingError: false,
    isNotFound: false,
    widgetData: [],
  },
});

export default (state: TopBannerState = initialState, action: IAction): TopBannerState =>
  produce(state, (draft) => {
    const { type, payload } = action;

    switch (type) {
      case ActionTypes.FETCH_ADPLATFORM_TOPBANNER_SUCCESS: {
        const { data, pageType }: { data: any; pageType: BANNER_REMOTE_CONFIG_TYPE } = payload;

        const stateUpdate = {
          isSuccess: true,
          isFetching: false,
          isFetchingError: false,
          isNotFound: false,
          widgetData: data,
        };

        draft[pageType] = stateUpdate;

        return;
      }

      case ActionTypes.FETCH_ADPLATFORM_TOPBANNER_CLEAR: {
        const { pageType }: { pageType: BANNER_REMOTE_CONFIG_TYPE } = payload;

        draft[pageType] = { ...initialState[pageType], isFetching: false };
        return;
      }

      case ActionTypes.FETCH_ADPLATFORM_TOPBANNER_NOT_FOUND: {
        const { pageType }: { pageType: BANNER_REMOTE_CONFIG_TYPE } = payload;

        draft[pageType] = { ...initialState[pageType], isFetching: false };
        return;
      }

      case ActionTypes.FETCH_ADPLATFORM_TOPBANNER_FAILED: {
        const { pageType }: { pageType: BANNER_REMOTE_CONFIG_TYPE } = payload;
        const stateUpdate = {
          isSuccess: true,
          isFetching: false,
          isFetchingError: true,
          isNotFound: false,
          widgetData: [],
        };
        draft[pageType] = stateUpdate;
        return;
      }
    }
  });
