import { produce } from 'immer';
import { ActionTypes } from './actions';
import { IAction, IGlobalSearchState } from './types';

export const initialState = Object.freeze<IGlobalSearchState>({
  popularOrTrending: {
    suggestions: [],
    message: '',
  },
  autocompleteResults: {
    suggestions: [],
    message: '',
    term: '',
  },
  isAutoCompleteFetching: false,
  isAutoCompleteFetchingError: false,
  isSuggestionFailed: false,
  widgets: [],
});

export default (state: IGlobalSearchState = initialState, action: IAction): IGlobalSearchState =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case ActionTypes.FETCH_AUTOCOMPLETE_DATA_PROGRESS: {
        draft.isAutoCompleteFetching = true;
        draft.isAutoCompleteFetchingError = false;
        draft.autocompleteResults.term = payload;
        return;
      }

      case ActionTypes.FETCH_SUGGESTIONS_DATA_SUCCESS_FROM_SESSION_DWEB:
      case ActionTypes.FETCH_SUGGESTIONS_DATA_SUCCESS_DWEB: {
        draft.popularOrTrending = payload;
        return;
      }

      case ActionTypes.FETCH_SUGGESTIONS_DATA_SUCCESS_FROM_SESSION_MWEB:
      case ActionTypes.FETCH_SUGGESTIONS_DATA_SUCCESS_MWEB: {
        draft.widgets = payload;
        return;
      }

      case ActionTypes.FETCH_SUGGESTIONS_DATA_FAILED: {
        draft.isSuggestionFailed = false;
        return;
      }

      case ActionTypes.FETCH_AUTOCOMPLETE_DATA_SUCCESS: {
        if (draft.autocompleteResults.term === payload.term) {
          draft.isAutoCompleteFetching = false;
          draft.isAutoCompleteFetchingError = false;
          draft.autocompleteResults = payload;
        }

        return;
      }

      case ActionTypes.FETCH_AUTOCOMPLETE_DATA_FAILED: {
        draft.isAutoCompleteFetching = false;
        draft.isAutoCompleteFetchingError = true;
        return;
      }
      case ActionTypes.CLEAR_AUTOCOMPLETE_DATA: {
        draft.isAutoCompleteFetching = false;
        draft.isAutoCompleteFetchingError = false;
        draft.autocompleteResults = {
          suggestions: [],
          message: '',
          term: '',
        };
        return;
      }
    }
  });
