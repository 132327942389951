// libs
import { produce } from 'immer';
import { ActionTypes, Action } from './types';

// constants
import { DEFAULT_PRODUCT_INFO, DEFAULT_WIDGET_INFO, RECO_WIDGET_TYPE } from './constants';

// defs
import { HybridPDPState } from './types';

// helpers
import { transformProductInfo, transformWidgetInfo } from './helpers';

const initialState: Readonly<HybridPDPState> = Object.freeze({
  productID: '',
  product: { fetching: false, statusCode: '', details: DEFAULT_PRODUCT_INFO },
  widgets: {
    fetching: false,
    statusCode: '',
    type: RECO_WIDGET_TYPE,
    details: DEFAULT_WIDGET_INFO,
  },
});

export default (state: HybridPDPState = initialState, action: Action): HybridPDPState =>
  produce(state, (draft) => {
    const { type, payload } = action;

    switch (type) {
      case ActionTypes.FETCH_DETAILS_PROGRESS: {
        draft.product = { ...initialState.product, fetching: true };
        return;
      }

      case ActionTypes.FETCH_DETAILS_SUCCESS: {
        draft.productID = payload.id;
        draft.product = {
          ...initialState.product,
          details: transformProductInfo(payload),
          fetching: false,
          statusCode: '200',
        };
        return;
      }

      case ActionTypes.FETCH_DETAILS_FAILURE: {
        draft.product = { ...initialState.product, fetching: false, statusCode: payload };
        return;
      }

      case ActionTypes.FETCH_WIDGETS_PROGRESS: {
        draft.widgets = { ...initialState.widgets, fetching: true };
        return;
      }

      case ActionTypes.FETCH_WIDGETS_SUCCESS: {
        draft.widgets = {
          ...initialState.widgets,
          fetching: false,
          statusCode: '200',
          details: transformWidgetInfo(payload),
        };
        return;
      }

      case ActionTypes.FETCH_WIDGETS_FAILURE: {
        draft.widgets = { ...initialState.widgets, fetching: false, statusCode: payload };
        return;
      }

      case ActionTypes.UPDATE_WIDGETS: {
        draft.widgets = {
          ...draft.widgets,
          details: {
            ...draft.widgets.details,
            fetchMoreProducts: payload?.has_more || false,
            products: [...draft.widgets.details.products, ...payload?.products],
            pageNumber: draft.widgets.details.pageNumber + 1,
          },
        };
      }
    }
  });
