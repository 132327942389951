// libs
import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose, Store } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { History } from 'history';

// root reducer
import createRootReducer from './rootReducer';

// defs
import { DeviceType } from './app/types';

interface IStoreParams {
  history: History;
  initialState?: { [key: string]: any };
  ssrHeaders?: { [key: string]: any };
  cookies?: { [key: string]: any };
  middleware?: any[];
  remoteConfigs?: Record<string, any>;
  SSRExperiments?: Record<string, string>;
  deviceType?: DeviceType;
}

const asyncReducers: { [key: string]: any } = {};
let historyCache: History;
let store: Store;

export const configureStore = ({
  history,
  ssrHeaders,
  cookies,
  initialState,
  middleware = [],
  remoteConfigs = {},
  deviceType,
}: IStoreParams) => {
  const devtools =
    process.env.NODE_ENV !== 'production' &&
    typeof window !== 'undefined' &&
    typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === 'function' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ actionsBlacklist: [] });

  const composeEnhancers = devtools || compose;
  historyCache = history;

  store = createStore(
    createRootReducer({
      history,
      ssrHeaders,
      cookies,
      asyncReducers,
      remoteConfigs,
      deviceType,
    }),
    initialState,
    composeEnhancers(applyMiddleware(...[thunk, routerMiddleware(history)].concat(...middleware)))
  );

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('./rootReducer', () => {
        const reducers = require('./rootReducer').default;
        store.replaceReducer(reducers(history, asyncReducers));
      });
    }
  }

  return store;
};

interface IReducer {
  key: string;
  reducer: { [key: string]: any };
}

export const injectReducer = ({ key, reducer }: IReducer) => {
  if (Object.hasOwnProperty.call(asyncReducers, key)) return;

  asyncReducers[key] = reducer;
  store.replaceReducer(createRootReducer(historyCache, asyncReducers));
};

export const WithReducer = ({ key, reducer }: IReducer) => {
  injectReducer({ key, reducer });
  return (Component: React.ComponentClass | JSX.Element) => Component;
};

export const getStore = () => store;

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default configureStore;
