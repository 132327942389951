// libs
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';

// reducers
import connectApp from './app/reducer';
import landingpage from './landingpage/reducers';
import details from './details/reducer';
import cart from './cart/reducer';
import widgets from './widgets/reducer';
import listing from './listing/reducer';
import connectRemoteConfig from './remoteConfigs/reducer';
import globalSearch from './globalSearch/reducer';
import ssrExperiments from '@nykaa/experiment-sdk/store/reducer';
import wishlist from './wishlist/reducer';
import cms from './cms/reducer';
import topBanner from './topBanner/reducer';
import { DeviceType } from './app/types';
import listingV2 from './listingV2/reducer';
import hybridPDP from './hybridPDP/reducer';
import dynamicPage from './dynamicPage/reducer';

interface RootReducer {
  history: History;
  ssrHeaders?: Record<string, string>;
  cookies?: Record<string, string>;
  asyncReducers?: any;
  remoteConfigs?: Record<string, any>;
  deviceType?: DeviceType;
}

const createRootReducer = ({
  history,
  ssrHeaders,
  cookies,
  asyncReducers,
  remoteConfigs,
  deviceType,
}: RootReducer) =>
  combineReducers({
    app: connectApp({ ssrHeaders, cookies, deviceType }),
    landingpage,
    cart,
    details,
    widgets,
    listing,
    globalSearch,
    ssrExperiments,
    wishlist,
    remoteConfigs: connectRemoteConfig(remoteConfigs),
    router: connectRouter(history),
    cms,
    topBanner,
    listingV2,
    hybridPDP,
    dynamicPage,
    ...asyncReducers,
  });

export default createRootReducer;
