let appInitPath = '';

/**
 * store application entry path
 * @param newPath string
 */
export const setAppInitPath = (newPath: string) => {
  appInitPath = newPath;
};

/**
 * Check if given path was the entry path for application
 * @param path string
 * @returns boolean
 */
export const isInitPath = (path: string) => appInitPath.toLowerCase() === path.toLowerCase();
