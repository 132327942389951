/**
 * @param {HTMLElement | string} element
 * @param {boolean} [alignToTop=true] - If true, the top of the element will be aligned to the top of the visible area of the scrollable ancestor.
 * @param {boolean} [smooth=true] - If true, then uses `scrollIntoViewOptions`.
 */

export const scrollElementIntoView = (
  element: Element | string,
  alignToTop = true,
  smooth = true
) => {
  if (typeof element === 'string') {
    const domElem = document.querySelector(element);

    if (domElem === null) {
      return;
    }

    element = domElem;
  }

  if (element && element.scrollIntoView !== undefined) {
    if (smooth) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: alignToTop ? 'start' : 'center',
      });
    } else {
      element.scrollIntoView(alignToTop);
    }
  }
};

/**
 * @export
 * @param {string} [selector="body"]
 */
export function hideOverflowY(selector = 'body', hasScrollBar = true) {
  const parent = document.querySelector<HTMLElement>(selector);
  if (parent === null) {
    return;
  }
  parent.style.overflow = 'hidden';

  if (selector === 'body') {
    parent.style.position = 'relative'; // fix for iOS Safari
  }
  if (hasScrollBar) {
    parent.style.marginRight = '15px';
  }
}

/**
 * @export
 * @param {string} [selector="body"]
 */
export function resetOverflowY(selector = 'body', hasScrollBar = true) {
  const parent = document.querySelector<HTMLElement>(selector);
  if (parent === null) {
    return;
  }

  parent.style.overflow = null;
  if (selector === 'body') {
    parent.style.position = null;
  }
  if (hasScrollBar) {
    parent.style.marginRight = null;
  }
}

export const scrollElementsIntoViewOptions = (
  elementId: string,
  scrollOptions: Record<string, any> = { behavior: 'smooth', block: 'end' }
) => {
  const element = document.getElementById(elementId);
  element && element.scrollIntoView(scrollOptions);
};

export const getVisualWidth = () => document.body.clientWidth;

const mobileRegExp = /mobile|phone|ipad|mini|tablet/i;
export const isMobileBrowser = () => mobileRegExp.test(window.navigator.userAgent);

export const focusElementById = (id: string) => {
  const element = document.getElementById(id) as HTMLElement;
  if (element) {
    element?.focus();
  }
};
