/**
 * Note on AbortController Web API :
 *
 * AbortController() : Creates a new AbortController object instance.
 *
 * AbortController.signal[Read only] : Returns a AbortSignal object instance, which can be used to communicate
 * with/abort a DOM request.
 *
 * The AbortSignal interface represents a signal object that allows you to communicate with a DOM request
 * (such as a Fetch) and abort it if required via an AbortController object.
 * In our case the AbortSignal Interface is controller.signal
 *
 * AbortSignal.aborted[Read only]: A Boolean that indicates whether the request(s) the signal is communicating
 * with is/are aborted (true) or not (false).
 *
 * AbortController.abort() : Aborts a DOM request before it has completed. This is able to abort fetch requests,
 *  consumption of any response Body, and streams.
 * In our case this method is called in a setTimeout
 */

// lib
import logger from 'shared/logger';
import { log } from 'shared/helpers/newRelic';

// constants
import { TAG_IDENTIFIER } from 'shared/helpers/SentryLogger/constants';
const API_TIMEOUT_TAG = 'Api Timeout';
const MAX_TIME_TO_WAIT = 12000;

// def
interface IReqHeaders {
  'Content-Type'?: string;
}
interface IFetchOptions {
  method?: string;
  mode?: RequestMode;
  referrer?: string;
  cache?: 'default' | 'no-store' | 'reload' | 'no-cache' | 'force-cache' | 'only-if-cached';
  credentials?: 'omit' | 'same-origin' | 'include';
  redirect?: 'follow' | 'error' | 'manual';
  body?:
    | ArrayBuffer
    | ArrayBufferView
    | NodeJS.ReadableStream
    | string
    | URLSearchParams
    | FormData;
  headers?: IReqHeaders;
}

let apiTimeoutValue = MAX_TIME_TO_WAIT;

export const setApiTimeoutValue = (maxTime: number) => {
  if (maxTime) {
    apiTimeoutValue = maxTime;
    return;
  }
  apiTimeoutValue = MAX_TIME_TO_WAIT;
};

const getApiTimeoutValue = () => {
  return apiTimeoutValue;
};

export const fetchTimeout = (
  fetchPolyfill: Function,
  url: string,
  fetchOptions: IFetchOptions,
  timeoutValue?: number
) => {
  const maxTime = timeoutValue ? timeoutValue : getApiTimeoutValue();
  const controller = new AbortController();

  const timeout = setTimeout(() => {
    controller.abort();

    if (__SERVER__) {
      logger.error({ ERROR: 'API_TIMEOUT', METHOD_NAME: 'API_TIMEOUT', api: url });
    } else {
      const { hostname, pathname } = new URL(url);
      log({
        errTitle: 'Error - Api timeout',
        tags: {
          [TAG_IDENTIFIER.MODULE]: API_TIMEOUT_TAG,
          [TAG_IDENTIFIER.HOSTNAME]: hostname,
          [TAG_IDENTIFIER.PATHNAME]: pathname,
        },
      });
    }
  }, maxTime);

  const promise = fetchPolyfill(url, {
    signal: controller.signal,
    ...fetchOptions,
  });

  return promise.finally(() => clearTimeout(timeout));
};
