import { RemoteConfigState } from './types';

export let initialState: RemoteConfigState;

const connectRemoteConfig = (remoteConfigs: RemoteConfigState = {}) => {
  initialState = Object.freeze<RemoteConfigState>({
    ...remoteConfigs,
  });

  // NO Actions in remoteConfig Reducer
  return (state: RemoteConfigState = initialState): RemoteConfigState => state;
};

export default connectRemoteConfig;
