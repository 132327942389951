export const EMAIL_REGEX =
  /* eslint-disable-next-line no-useless-escape */
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const ListingPageRegex = new RegExp('/c/');
export const SearchPageRegex = new RegExp('/catalogsearch/result/');

export const DetailsPageRegex = new RegExp('/p/');

export const landingPageRegex = new RegExp('/lp/');

export const offerPageRegex = new RegExp('offer.html');
export const beautyRxp = new RegExp('^https://www.nykaa.com', 'i');
export const nykaaManRxp = new RegExp('^https://www.nykaaman.com', 'i');
