import { produce } from 'immer';
import { ActionTypes } from 'shared/store/wishlist/actions';
import { IAction, IWishlistState } from 'shared/store/wishlist/types';

export const initialState = Object.freeze<IWishlistState>({
  wishlistIds: [],
  products: [],
  totalCount: -1,
  pagesFetched: [],
  showAnimationForProductId: '',
  totalProductOnPageLoad: 0,
  isProductFetching: false,
  productFetchError: null,
});

export default (state: IWishlistState = initialState, action: IAction): IWishlistState =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case ActionTypes.SET_WISHLIST_IDS: {
        draft.wishlistIds = payload;
        return;
      }
      case ActionTypes.ADD_PRODUCT_ID_TO_WISHLIST: {
        const wishlistIds = [...draft.wishlistIds];

        if (wishlistIds.indexOf(payload) === -1) {
          wishlistIds.push(payload);
          draft.wishlistIds = wishlistIds;
        }

        return;
      }

      case ActionTypes.REMOVE_PRODUCT_ID_FROM_WISHLIST: {
        const updatedWishlist = [...draft.wishlistIds];

        const index = updatedWishlist.indexOf(payload);

        if (index > -1) {
          updatedWishlist.splice(index, 1);
          draft.wishlistIds = updatedWishlist;
        }
        return;
      }

      case ActionTypes.ADD_PRODUCTS_TO_WISHLIST_PRODUCTS: {
        const totalProductOnPageLoad = draft.totalProductOnPageLoad;
        const { products, productsCount, page } = payload;

        if (totalProductOnPageLoad === -1) {
          draft.totalProductOnPageLoad = productsCount;
        }
        draft.products = [...draft.products, ...products];
        draft.totalCount = productsCount;
        draft.pagesFetched.push(page);
        return;
      }

      case ActionTypes.REMOVE_PRODUCT_FROM_WISHLIST_PRODUCTS: {
        const updatedWishlist = [...draft.wishlistIds];
        const index = updatedWishlist.indexOf(payload);

        if (index > -1) {
          updatedWishlist.splice(index, 1);
          draft.wishlistIds = updatedWishlist;

          const products = [...draft.products];
          draft.totalCount = draft.totalCount - 1;
          draft.products = products.filter((product) => product.id != payload);
          return;
        }
        return;
      }

      case ActionTypes.ADD_PRODUCT_TO_WISHLIST_PRODUCTS: {
        const { product, productsCount } = payload;

        draft.products = [...draft.products, product];
        draft.totalCount = productsCount;
        return;
      }

      case ActionTypes.RESET_WISHLIST_PRODUCTS_COUNT_AND_PAGES: {
        draft.products = [];
        draft.totalCount = 0;
        draft.pagesFetched = [];
        return;
      }

      case ActionTypes.UPDATE_SHOW_ANIMATION_FOR_PRODUCTID: {
        draft.showAnimationForProductId = payload;
        return;
      }
      case ActionTypes.FETCH_PRODUCT_DATA:
        draft.isProductFetching = true;
        draft.productFetchError = null;
        return;
      case ActionTypes.UPDATE_PRODUCT_SKU_FROM_WISHLIST_STORE: {
        const newProducts = state.products.map((product) => {
          if (product.id !== payload.productId) return { ...product };
          return {
            ...product,
            sizeOptions: payload.skuData,
          };
        });
        draft.products = newProducts;
        draft.isProductFetching = false;
        draft.productFetchError = null;
        return;
      }
      case ActionTypes.FETCH_PDP_WISHLIST_ERROR: {
        draft.isProductFetching = false;
        draft.productFetchError = payload;
        return;
      }
      case ActionTypes.SET_IS_UPDATING: {
        draft.isUpdating = payload;
        return;
      }
    }
  });
