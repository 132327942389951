import { produce } from 'immer';
import get from 'lodash/get';
import { ActionTypes } from './actions';
import { IAction, IDetailState } from './types';
import { getUpdatedCustomisationData } from './helpers';

export const initialState = Object.freeze<IDetailState>({
  isFetching: false,
  isFetchingError: false,
  isNotFound: false,
  errorSKU: '',
  errorStatusCode: '',
  fetchingSKU: '',
  isFetchingAplusDataProgress: false,
  isFetchingAplusDataError: false,
  aplusErrorStatusCode: '',
  isAPlusContentFetched: false,
  isFetchingEcomPolicyProgress: false,
  isFetchingEcomPolicyError: false,
  ecomPolicyData: {
    cash_on_delivery: '',
    return_and_exchanges: '',
    delivery_details: '',
  },
  reviews: {
    data: [],
    isFetching: true,
    isError: false,
    page: 0,
  },
  customerReviewsImages: {
    reviewData: {},
    reviewImages: [],
    pageKey: '',
    isFetching: true,
    isError: false,
    total: 0,
    size: 0,
  },
  exploreImages: [],
  productOffers: {
    coupons: [],
    offers: [],
    tradeOffers: [],
    paymentOffers: [],
    bestOffers: [],
    bestOfferMessage: '',
    offerBestPrice: 0,
    bestOfferIdentifier: 0,
    bestOfferTotalDiscount: 0,
    productId: 0,
    fetching: false,
  },
});

export default (state: IDetailState = initialState, action: IAction): IDetailState =>
  produce(state, (draft) => {
    const { type, payload } = action;

    switch (type) {
      case ActionTypes.SET_SKU_DATA: {
        draft.skuData = payload;
        return;
      }
      case ActionTypes.RESET_ERROR_SKU: {
        draft.errorSKU = '';
        return;
      }

      case ActionTypes.FETCH_SKU_DATA_PROGRESS: {
        draft.fetchingSKU = payload;
        draft.isFetching = true;
        draft.isFetchingEcomPolicyProgress = false;
        draft.isFetchingEcomPolicyError = false;
        // reset customer reviews on SKU change
        draft.customerReviewsImages = {
          ...initialState.customerReviewsImages,
          reviewData: {},
          reviewImages: [],
        };
        draft.ecomPolicyData = {
          cash_on_delivery: '',
          return_and_exchanges: '',
          delivery_details: '',
        };
        return;
      }

      case ActionTypes.FETCH_SKU_DATA_SUCCESS: {
        draft.isFetching = false;
        draft.isFetchingError = false;
        draft.isNotFound = false;
        draft.isFetchingAplusDataError = false;
        draft.skuData = {
          ...payload.pdp,
          product: {
            ...payload.pdp.product,
            review_rating_json: payload?.pdp?.product?.review_rating_json,
          },
        };
        draft.selectedSKU = payload.selectedSKU;
        draft.selectedSKUId = payload.skuId;
        draft.errorSKU = '';
        draft.errorStatusCode = '';
        return;
      }

      case ActionTypes.FETCH_SKU_DATA_FAILED: {
        const { statusCode, productID } = payload;
        draft.isFetching = false;
        draft.isNotFound = false;
        draft.isFetchingError = true;
        draft.errorSKU = productID;
        draft.errorStatusCode = statusCode;
        return;
      }

      case ActionTypes.FETCH_SKU_DATA_NOT_FOUND: {
        draft.isFetching = false;
        draft.isNotFound = true;
        draft.isFetchingError = false;
        draft.errorSKU = payload;
        return;
      }

      case ActionTypes.FETCH_A_PLUS_DATA_PROGRESS: {
        draft.isFetchingAplusDataProgress = true;
        draft.isFetchingAplusDataError = false;
        return;
      }

      case ActionTypes.FETCH_A_PLUS_DATA_SUCCESS: {
        draft.isFetchingAplusDataError = false;
        draft.isAPlusContentFetched = true;
        if (draft?.skuData?.product) {
          draft.skuData.product.aPlusContent = payload;
        }
        return;
      }

      case ActionTypes.FETCH_A_PLUS_DATA_EMPTY_RESPONSE: {
        draft.isAPlusContentFetched = true;
        draft.isFetchingAplusDataError = false;
        if (draft?.skuData?.product) {
          draft.skuData.product.aPlusContent = '';
        }
        return;
      }

      case ActionTypes.FETCH_A_PLUS_DATA_FAILED: {
        const { statusCode } = payload;

        draft.isFetchingAplusDataProgress = false;
        draft.isFetchingAplusDataError = true;
        draft.aplusErrorStatusCode = statusCode;
        draft.isAPlusContentFetched = true;

        return;
      }

      case ActionTypes.FETCH_EXPLORE_DATA_SUCCESS: {
        const { images, productID } = payload;

        if (get(draft, 'skuData.product.id') === productID) {
          draft.exploreImages = [...images];
          draft.exploreProductID = productID;
        }

        return;
      }

      case ActionTypes.FETCH_ALL_REVIEWS: {
        draft.reviews.isFetching = true;
        draft.reviews.isError = false;
        return;
      }

      case ActionTypes.CLEAR_ALL_REVIEWS: {
        draft.reviews.isFetching = true;
        draft.reviews.isError = false;
        draft.reviews.data = [];
        draft.reviews.page = 0;
        return;
      }

      case ActionTypes.UPDATE_ALL_REVIEWS_DATA: {
        draft.reviews.isFetching = false;
        draft.reviews.isError = false;
        draft.reviews.data = [...draft.reviews.data, ...payload.data];
        draft.reviews.page = payload.page;
        return;
      }

      case ActionTypes.FETCH_ALL_IMAGES_FOR_REVIEWS: {
        draft.customerReviewsImages.isFetching = draft.customerReviewsImages.pageKey === '';
        draft.customerReviewsImages.isError = false;
        return;
      }

      case ActionTypes.FETCH_ALL_IMAGES_FOR_REVIEWS_FAILED: {
        draft.customerReviewsImages.isFetching = false;
        draft.customerReviewsImages.isError = true;

        return;
      }

      case ActionTypes.UPDATE_ALL_IMAGES_FOR_REVIEWS: {
        draft.customerReviewsImages = {
          isFetching: false,
          isError: false,
          pageKey: payload.pageKey,
          total: payload.total,
          size: payload.size,
          reviewData: {
            ...draft.customerReviewsImages.reviewData,
            ...payload.reviewData,
          },
          reviewImages: [...draft.customerReviewsImages.reviewImages, ...payload.reviewImages],
        };
        return;
      }

      case ActionTypes.FETCH_ALL_REVIEWS_FAILED: {
        draft.reviews.isFetching = false;
        draft.reviews.isError = true;
        draft.reviews.data = [];
        draft.reviews.page = 0;
        return;
      }

      case ActionTypes.SET_IS_LIKED_BY_USER: {
        const reviews = [...draft.reviews.data];

        const id = parseInt(payload.id);

        for (let i = 0; i < reviews.length; i++) {
          if (reviews[i].id === id) {
            reviews[i].isLikedByUser = payload.like;

            draft.reviews.data = [...reviews];
            return;
          }
        }

        return;
      }

      case ActionTypes.SET_SELECTED_SKU_ID: {
        const { skuId, oneSize } = payload;
        if (oneSize) {
          draft.selectedSKU = state.skuData?.product;
          draft.selectedSKUId = state.skuData?.product.id;
          return;
        }
        const selectedSKU = (draft?.skuData?.product?.sizeOptions.options || []).find(
          (sku) => sku.id === skuId
        );

        draft.selectedSKU = selectedSKU;
        draft.selectedSKUId = skuId;
        return;
      }

      case ActionTypes.RESET_SELECTED_SKU_ID: {
        draft.selectedSKU = undefined;
        draft.selectedSKUId = undefined;
        return;
      }

      case ActionTypes.FETCH_ECOM_POLICY_DATA_PROGRESS: {
        draft.isFetchingEcomPolicyProgress = true;
        draft.isFetchingEcomPolicyError = false;
        return;
      }

      case ActionTypes.FETCH_ECOM_POLICY_DATA_SUCCESS: {
        const { selectedEcomPolicyData, selectedEcomPolicy } = payload;
        draft.isFetchingEcomPolicyProgress = false;
        draft.isFetchingEcomPolicyError = false;
        draft.ecomPolicyData = {
          ...draft.ecomPolicyData,
          [selectedEcomPolicy]: selectedEcomPolicyData,
        };
        return;
      }

      case ActionTypes.FETCH_ECOM_POLICY_DATA_FAILED: {
        draft.isFetchingEcomPolicyProgress = false;
        draft.isFetchingEcomPolicyError = true;
        return;
      }
      case ActionTypes.SET_CUSTOMISATION_SECTION_VALUE: {
        draft.skuData.product.customization_form = getUpdatedCustomisationData(payload);
        return;
      }

      case ActionTypes.FETCH_DELIVERY_OR_SHIPPING_DATE_SUCCESS: {
        draft.skuData.product.deliveryDetails = {
          ...draft.skuData?.product.deliveryDetails,
          deliveryDataForSKUs: payload,
        };
        return;
      }

      case ActionTypes.SET_PRODUCT_OFFERS: {
        draft.productOffers = payload.productOffers;
        return;
      }

      case ActionTypes.SET_OFFERS_FAILURE: {
        draft.productOffers = { ...initialState.productOffers, fetching: false };
        return;
      }

      case ActionTypes.FETCH_ESTIMATED_DELIVERY_DETAILS: {
        draft.skuData.product.deliveryDetails = {
          ...draft.skuData?.product.deliveryDetails,
          eddDetails: payload,
        };
        return;
      }
    }
  });
