// defs
import { AppState, IHeaderOptions } from './types';

// helpers
import { WEB_APP_TYPE } from 'shared/constants/index';

// action types
export const ActionTypes = {
  SET_HEADER_OFFER: 'app/set-header-offer',
  FETCH_HEADER_OFFER: 'app/fetch-header-offer',
  FETCH_HEADER_OFFER_FAILED: 'app/fetch-header-offer-failed',
  FETCH_MENU_PROGRESS: 'app/fetch_menu_progress',
  FETCH_MENU_SUCCESS: 'app/fetch_menu_success',
  FETCH_MENU_FAILED: 'app/fetch_menu_failed',
  FETCH_USER_PROGRESS: 'app/fetch_user_progress',
  FETCH_USER_SUCCESS: 'app/fetch_user_success',
  FETCH_USER_FAILED: 'app/fetch_user_failed',
  UPDATE_CART_COUNT: 'app/update_cart_count',
  INCREMENT_CART_COUNT: 'app/increment_cart_count',
  UPDATE_CUSTOMER_DATA: 'app/update_customer_data',
  UPDATE_CUSTOMER_DETAILS: 'app/update_customer_details',
  SET_SSR_HEADERS: 'app/set_ssr_headers',
  SET_RESPONSE_CODE: 'app/set_response_code',
  SET_HEADER_OPTIONS: 'app/set_header_options',
  RESET_HEADER_OPTIONS: 'app/reset_header_options',
  SET_IN_PROGRESS: 'app/set-in-progress',
  SET_HEADER_VISIBILITY: 'app/set_header_visibility',
  SET_FOOTER_VISIBILITY: 'app/set_footer_visibility',
  SET_FOOTER_DOWNLOADAPP_SECTION_VISIBILITY: 'app/set_footer_downloadapp_section_visibility',
  SET_WEB_APP_TYPE: 'app/set_web_app_type',
  SHOW_LOGIN_OPTIONS: 'app/show_login_options',
  SET_APP_SEARCH_TERM: 'app/set_app_search_term',
  REDIRECT: 'app/redirect',
  HIDE_LOGIN_INFO_TOAST: 'app/hide_login_info_toast',
  SHOW_CART_NUDGE: 'app/show_cart_nudge',
  SHOW_WISHLIST_NUDGE: 'app/show_wishlist_nudge',
  SET_INCOGNITO_MODE: 'app/set_incognito_mode',
};

// actions
export const updateAppCartCount = (count: number) => ({
  type: ActionTypes.UPDATE_CART_COUNT,
  payload: count,
});

export const incrementAppCartCount = (payload: number) => ({
  type: ActionTypes.INCREMENT_CART_COUNT,
  payload,
});

export const updateAppUser = (user: AppState['user']) => ({
  type: ActionTypes.UPDATE_CUSTOMER_DATA,
  payload: user,
});

export const updateStatusCode = (statusCode: number) => ({
  type: ActionTypes.SET_RESPONSE_CODE,
  payload: statusCode,
});

export const setHeaderOptions = (payload: IHeaderOptions) => ({
  type: ActionTypes.SET_HEADER_OPTIONS,
  payload,
});

export const resetHeaderOptions = () => ({
  type: ActionTypes.RESET_HEADER_OPTIONS,
});

export const setInProgress = (inProgress: boolean) => ({
  type: ActionTypes.SET_IN_PROGRESS,
  payload: inProgress,
});

export const showHeader = () => ({
  type: ActionTypes.SET_HEADER_VISIBILITY,
  payload: true,
});

export const hideHeader = () => ({
  type: ActionTypes.SET_HEADER_VISIBILITY,
  payload: false,
});

export const showFooter = () => ({
  type: ActionTypes.SET_FOOTER_VISIBILITY,
  payload: true,
});

export const hideFooter = () => ({
  type: ActionTypes.SET_FOOTER_VISIBILITY,
  payload: false,
});

export const showFooterDownloadAppSection = () => ({
  type: ActionTypes.SET_FOOTER_DOWNLOADAPP_SECTION_VISIBILITY,
  payload: true,
});

export const hideFooterDownloadAppSection = () => ({
  type: ActionTypes.SET_FOOTER_DOWNLOADAPP_SECTION_VISIBILITY,
  payload: false,
});

export const setWebAppType = (value: WEB_APP_TYPE) => ({
  type: ActionTypes.SET_WEB_APP_TYPE,
  payload: value,
});

export const updateCustomerDetails = (user: AppState['user']['customerData']) => ({
  type: ActionTypes.UPDATE_CUSTOMER_DETAILS,
  payload: user,
});

export const setAppSearchTerm = (value: string) => ({
  type: ActionTypes.SET_APP_SEARCH_TERM,
  payload: value,
});

export const redirect = (value: string, statusCode: number = 302) => ({
  type: ActionTypes.REDIRECT,
  payload: {
    redirectURL: value,
    statusCode,
  },
});

export const hideLoginInfoToast = () => ({
  type: ActionTypes.HIDE_LOGIN_INFO_TOAST,
});
export const setCartNudge = (toastCount = 0) => ({
  type: ActionTypes.SHOW_CART_NUDGE,
  payload: toastCount,
});

export const setShowWishlistNudge = (toastCount = 0) => ({
  type: ActionTypes.SHOW_WISHLIST_NUDGE,
  payload: toastCount,
});

export const setIncognitoMode = (value: boolean) => ({
  type: ActionTypes.SET_INCOGNITO_MODE,
  payload: value,
});
