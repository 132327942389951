//build config imports
import { buildConfig as footlockerConfig } from './configs/footlockerConfig';
import { buildConfig as fashionConfig } from './configs/fashionConfig';

//enums
import { DOMAIN } from './constants';

//hook for domain specific config
export const getDomainConfig = () => {
  switch (process.env.DOMAIN_NAME) {
    case DOMAIN.FOOT_LOCKER:
      return footlockerConfig;
    case DOMAIN.NYKAA_FASHION:
      return fashionConfig;
    default:
      return fashionConfig;
  }
};
