export default {
  WIDGETS: {
    ORDERS: {
      key: 'ORDERS',
      title: 'Orders',
      subTitle: 'Check your Order Status',
      isPrivate: true,
      action_url: '/sales/order/history',
      icon_url: 'https://images-static.nykaa.com/uploads/000900b1-6c39-4400-9bc8-f7d61659fabc.png',
      isEnabled: true,
    },
    WISHLIST: {
      key: 'WISHLIST',
      title: 'Wishlist',
      subTitle: 'Shop from your wishlist',
      isPrivate: true,
      action_url: '/wishlist',
      icon_url: 'https://images-static.nykaa.com/uploads/c2a1078d-32c7-426b-91d5-e11b8d02fa7a.png',
      isEnabled: true,
    },
    MY_NYKAA_FASHION: {
      key: 'MY_NYKAA_FASHION',
      title: 'My Nykaa Fashion',
      subTitle: 'Personalized shopping experience',
      isPrivate: true,
      action_url: '',
      icon_url: 'https://images-static.nykaa.com/uploads/0e0ab2d3-669f-48c4-952e-f5b6af28088a.png',
      isEnabled: true,
    },
    REWARDS: {
      key: 'REWARDS',
      title: 'Rewards',
      subTitle: 'Redeem and check your rewards earned',
      isPrivate: false,
      action_url: '',
      icon_url: '',
      isEnabled: true,
      otherData: {
        CG: {
          enabled: false,
          experimentId: 'customer-glue-stw-ab',
          experimentVariant: 'show-cg-campaign',
          rewardsUrl: '/cp/cg-rewards',
          label: 'My Rewards',
          asset: 'https://images-static.nykaa.com/uploads/89e2cc5d-8603-4df6-aacf-feb556d656f3.png',
        },
      },
    },
    HELP_CENTER: {
      key: 'HELP_CENTER',
      title: 'Help Center',
      subTitle: 'Get help from FAQs or raise a concern',
      isPrivate: false,
      action_url: 'https://www.footlocker.co.in/help-center',
      action_url_preprod: 'https://www.footlocker.co.in/help-center',
      icon_url: '',
      isEnabled: true,
    },
    PROFILE: {
      key: 'PROFILE',
      title: 'Profile',
      subTitle: 'View and update your profile',
      isPrivate: false,
      action_url: '/my-profile',
      icon_url: '',
      isEnabled: true,
    },
    ADDRESS_BOOK: {
      key: 'ADDRESS_BOOK',
      isEnabled: true,
      isPrivate: true,
      title: 'Address Book',
      subTitle: 'Update/change your shipping address',
      action_url: '/address-book',
    },
    GOOGLE_STORE: {
      key: 'GOOGLE_STORE',
      title: 'Google Play',
      action_url: 'https://play.google.com/store/apps/details?id=com.fsn.nds',
      image_url: '/pub/static/frontend/Nykaa/theme001/en_US/images/glplay.png',
      isEnabled: true,
    },
    APPLE_STORE: {
      key: 'APPLE_STORE',
      title: 'Apple Store',
      action_url: 'https://itunes.apple.com/in/app/nykaa-design-studio/id1439872423?mt=8',
      image_url: '/pub/static/frontend/Nykaa/theme001/en_US/images/appleStore.png',
      isEnabled: true,
    },
    SHIPPING_POLICY: {
      key: 'SHIPPING_POLICY',
      title: 'Shipping Policy',
      action_url: '/cp/shipping-policy',
      icon_url: '',
      isEnabled: true,
    },

    TERMS_AND_CONDITIONS: {
      key: 'TERMS_AND_CONDITIONS',
      title: 'Terms and Conditions',
      action_url: '/cp/terms-conditions',
      icon_url: '',
      isEnabled: true,
    },
    PRIVACY_POLICY: {
      key: 'PRIVACY_POLICY',
      title: 'Privacy Policy',
      action_url: '/cp/privacy-policy',
      icon_url: '',
      isEnabled: true,
    },
    ABOUT_US: {
      key: 'ABOUT_US',
      title: 'About Us',
      action_url: '/cp/about-us',
      icon_url: 'https://asset.footlocker.co.in/footlocker-images/about-us-account.svg',
      isEnabled: true,
    },
    RESPONSIBLE_DISCLOSURE: {
      key: 'RESPONSIBLE_DISCLOSURE',
      title: 'Return Policy',
      action_url: '/cp/returns-refund-policy',
      icon_url: '',
      isEnabled: true,
    },

    ORDERS_AND_WISHLIST: {
      key: 'ORDERS_AND_WISHLIST',
      title: 'ORDERS AND WISHLIST',
      children: ['ORDERS', 'WISHLIST'],
      isEnabled: true,
    },
    SUPPORT: {
      key: 'SUPPORT',
      title: 'SUPPORT',
      children: ['CHAT_WITH_US', 'HELP_CENTER'],
      isEnabled: true,
    },
    PERSONALIZATION: {
      key: 'PERSONALIZATION',
      title: 'PERSONALIZATION',
      children: ['PROFILE', 'ADDRESS_BOOK'],
      isEnabled: true,
    },
    LOGOUT: {
      key: 'LOGOUT',
      title: 'Logout',
      action_url: '/customer/account/logout',
      isEnabled: true,
    },
  },
  CONTENT: [
    {
      key: 'SECTIONS',
      type: 'sections',
      isEnabled: true,
      children: ['ORDERS_AND_WISHLIST', 'SUPPORT', 'PERSONALIZATION'],
    },
    {
      key: 'FOOTER',
      type: 'footer',
      icon_url: '',
      isEnabled: true,
      children: [
        'SHIPPING_POLICY',
        'TERMS_AND_CONDITIONS',
        'PRIVACY_POLICY',
        'ABOUT_US',
        'RESPONSIBLE_DISCLOSURE',
      ],
    },
  ],
};
