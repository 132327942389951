// lib
import { produce } from 'immer';

// action
import { ActionTypes } from './actions';

// helpers
import { getInlineScriptSource } from './helpers';

// def
import { IAction, ICMSPageState } from './types';

export const initialState = Object.freeze<ICMSPageState>({
  isFetching: false,
  isFetchingError: false,
  isNotFound: false,
  cmsData: {
    content: '',
    description: '',
    id: '',
    jsContent: '',
    keyword: '',
    title: '',
  },
});

export default (state: ICMSPageState = initialState, action: IAction): ICMSPageState =>
  produce(state, (draft) => {
    const { type, payload } = action;

    switch (type) {
      case ActionTypes.FETCH_CMSPAGE_DATA_PROGRESS: {
        draft.isFetching = true;
        return;
      }

      case ActionTypes.FETCH_CMSPAGE_DATA_FAILED: {
        draft.isFetching = false;
        draft.isFetchingError = true;
        draft.isNotFound = false;
        return;
      }

      case ActionTypes.FETCH_CMSPAGE_DATA_NOT_FOUND: {
        draft.isFetching = false;
        draft.isFetchingError = false;
        draft.isNotFound = true;
        return;
      }

      case ActionTypes.FETCH_CMSPAGE_DATA_SUCCESS: {
        const { data } = payload;
        draft.isFetching = false;
        draft.isFetchingError = false;
        draft.isNotFound = false;
        draft.cmsData = {
          ...data,
          jsContent: getInlineScriptSource(data.jsContent),
        };
        return;
      }
    }
  });
