// helpers
import { nykaaRxp } from 'shared/helpers/utils';
import { beautyRxp, nykaaManRxp } from 'shared/helpers/utils/regex';
import { getDomainConfig } from 'shared/domainConfig/helpers';

// libs
import isEmpty from 'lodash/isEmpty';

// constants
import {
  FILTER_SEPARATOR,
  OBJECT_JOIN_CHAR,
  OBJECT_JOIN_CHAR_ENCRYPT,
  PRICE_JOIN_CHAR,
} from 'shared/layouts/Listing/Api/constants';
import { PUBLIC_DOMAIN } from 'shared/constants';

const HTTP_REGEXP = /^http/i;

const QUERY_STRING_SRC_IDENTIFIER = 'src';
const QUERY_STRING_SRC_VALUE = 'app';
const KEYWORD_IDENTIFIER = '<Keyword>';

// TODO: The definition to the function is incorrect. FIx and analyze the consequences
export const isOurURL = (URL: string) => nykaaRxp.test(URL) || HTTP_REGEXP.test(URL) === false;

export const getCleanUrl = (URL: string) => (nykaaRxp.test(URL) ? URL.replace(nykaaRxp, '') : URL);

export const generateNykaaUrl = (url: string) => {
  const { SHARE } = getDomainConfig();
  let requiredUrl = url;
  if (url.indexOf(SHARE.PUBLIC_DOMAIN) > -1) {
    // get pathanme + search from url
    requiredUrl = url.split(PUBLIC_DOMAIN)[1];
  }

  return `${SHARE.CANONICAL_HOMEPAGE_URL}${requiredUrl}`;
};

export const getPLPFilterParams = (data: string = '', sort: string = '') => {
  const sortParam = sort.trim();
  const dataSplit = data.split('&');

  const defaultValue =
    sortParam !== '' ? `sort=${sortParam.replace(OBJECT_JOIN_CHAR, OBJECT_JOIN_CHAR_ENCRYPT)}` : '';

  return dataSplit.reduce((filterValue, item) => {
    const newValue =
      item.includes('-') && !item.includes('-*')
        ? item.trim().replace('-', PRICE_JOIN_CHAR)
        : item.trim().replace(',', OBJECT_JOIN_CHAR) + OBJECT_JOIN_CHAR;

    return filterValue ? filterValue + FILTER_SEPARATOR + newValue : newValue;
  }, defaultValue);
};

const isOurHome = (currentPath: string) => {
  return currentPath === '/' || currentPath === '';
};

export const isSourceApp = (search = '') => {
  const urlParams = new URLSearchParams(search);

  // check for src=app in the url
  return (
    urlParams.has(QUERY_STRING_SRC_IDENTIFIER) &&
    // @ts-ignore
    urlParams.get(QUERY_STRING_SRC_IDENTIFIER).toLowerCase() === QUERY_STRING_SRC_VALUE
  );
};

export const isWebView = (location: Location<any>) => {
  if (isOurURL(location.href) === false) return false;

  return isSourceApp(location.search) && !isOurHome(location.pathname);
};

export const addQueryStringToURL = (url: string, queryString: string) => {
  if (url.indexOf('?') > -1) {
    return `${url}&${queryString}`;
  }

  return `${url}?${queryString}`;
};

export const isCrossPlatform = (url: string) => {
  return beautyRxp.test(url) || nykaaManRxp.test(url);
};

export const getHost = () => {
  return `${window.location.protocol}//${window.location.host}`;
};

// all dynamic url will have a format of "/dp/:slug" where slug will be used as a search keyword
export const isDynamicPageURL = (pathname: string) => {
  if (!pathname) return false;

  return pathname.startsWith('/dp/');
};

export const getDynamicPageSlugFromPathname = (pathname: string) => {
  if (!pathname || !isDynamicPageURL(pathname)) return '';
  return pathname.split('/dp/')[1] || '';
};

export const replaceAllSubstring = (text = '', replaceWith = '', target = KEYWORD_IDENTIFIER) => {
  const regex = new RegExp(target, 'g');
  return text.replace(regex, replaceWith);
};

export const isCouponOfferPage = (search: string) => {
  const params = new URLSearchParams(search);
  return !isEmpty(params.get('coupon_offer_id'));
};
