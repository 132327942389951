import React from 'react';
import ResponsiveContext from './ResponsiveContext';
import { BREAKPOINTS } from 'shared/styles/base';

interface IResponsiveObserverProps {
  children: React.ReactNode;
  deviceWidth: number;
}

interface IResponsiveObserverState {
  deviceWidth: number;
}

class ResponsiveObserver extends React.PureComponent<
  IResponsiveObserverProps,
  IResponsiveObserverState
> {
  public static defaultProps = {
    deviceWidth: BREAKPOINTS.MOBILE,
  };

  public state = {
    deviceWidth: this.props.deviceWidth,
  };

  public render() {
    const { children } = this.props;
    const { deviceWidth } = this.state;
    return (
      <ResponsiveContext.Provider value={{ deviceWidth }}>{children}</ResponsiveContext.Provider>
    );
  }

  public componentDidMount() {
    window.addEventListener('resize', this.onResize, false);
    this.onResize();
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.onResize, false);
  }

  private onResize = () => this.setState({ deviceWidth: document.documentElement.clientWidth });
}

export default ResponsiveObserver;
