// libs
import { produce } from 'immer';

// types
import { DynamicPageState, Action } from './types';
import { ActionTypes } from './action';

const initialState = Object.freeze<DynamicPageState>({
  isDynamicPageDataFetching: true,
  isDynamicPageDataFetched: false,
  dynamicPageData: {},
});

export default (state: DynamicPageState = initialState, action: Action): DynamicPageState =>
  produce(state, (draft) => {
    const { type, payload } = action;

    switch (type) {
      case ActionTypes.SET_DYNAMIC_PAGE_DATA: {
        draft.isDynamicPageDataFetching = false;
        draft.isDynamicPageDataFetched = true;
        draft.dynamicPageData = {
          ...draft.dynamicPageData,
          ...payload,
        };
      }
    }
  });
