// helpers
import isEmpty from 'lodash/isEmpty';
import Storage from 'shared/helpers/storage';
import { log } from 'shared/helpers/newRelic';

// defs
import { IParamExperimentProps, IParamExperiementValues } from './types';

// constants
import { ERROR_TAGS, TAG_IDENTIFIER } from 'shared/helpers/SentryLogger/constants';
const API_EXPERIMENT_KEY = 'API_AB_EXPERIMENT';
let memoizedResult: IParamExperimentProps = {};

const setStorage = (value: IParamExperimentProps) => {
  try {
    memoizedResult = value;

    if (isEmpty(value)) {
      Storage.removeItem(API_EXPERIMENT_KEY);
      return;
    }

    Storage.setItem(API_EXPERIMENT_KEY, JSON.stringify(value));
  } catch (error) {
    log({
      errTitle: 'Error in setting storage - apiExperiment',
      error,
      tags: { [TAG_IDENTIFIER.MODULE]: ERROR_TAGS.API_EXPERIMENT },
    });
  }
};

// method to fetch only single url details stored in the session
export const getUrlInfo = (url: string): IParamExperiementValues | undefined => {
  try {
    if (memoizedResult === undefined) {
      memoizedResult = JSON.parse(Storage.getItem<string>(API_EXPERIMENT_KEY) || '{}');
    }

    if (url === undefined) {
      return;
    }

    return memoizedResult[url];
  } catch (error) {
    log({
      errTitle: 'Error in getUrlInfo - apiExperiment',
      error,
      tags: { [TAG_IDENTIFIER.MODULE]: ERROR_TAGS.API_EXPERIMENT },
    });
  }
  return;
};

// method to store the data in the session to modify the API
export const setSessionInfo = (value: IParamExperimentProps) => {
  try {
    setStorage(value);
  } catch (error) {
    log({
      errTitle: 'Error in setSessionInfo - apiExperiment',
      error,
      tags: { [TAG_IDENTIFIER.MODULE]: ERROR_TAGS.API_EXPERIMENT },
    });
  }
};

// clear all data from session to modify API via api-experiment
export const clearSessionInfo = () => {
  setStorage({});
};
