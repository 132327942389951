export const ActionTypes = {
  FETCHING_WISHLIST_IDS: 'wishlist/fethcing_wishlist_ids',
  SET_WISHLIST_IDS: 'wishlist/update_wishlist_ids',
  SET_IS_UPDATING: 'wishlist/set_is_updating',
  ADD_PRODUCT_ID_TO_WISHLIST_INIT: 'wishlist/add_product_id_to_wishlist_init',
  ADD_PRODUCT_ID_TO_WISHLIST: 'wishlist/add_product_id_to_wishlist',
  REMOVE_PRODUCT_ID_FROM_WISHLIST_INIT: 'wishlist/remove_product_id_from_wishlist_init',
  REMOVE_PRODUCT_ID_FROM_WISHLIST: 'wishlist/remove_product_id_from_wishlist',
  ADD_PRODUCTS_TO_WISHLIST_PRODUCTS: 'wishlist/add_products_to_wishlist_products',
  ADD_PRODUCT_TO_WISHLIST_PRODUCTS: 'wishlist/add_product_to_wishlist_products',
  REMOVE_PRODUCT_FROM_WISHLIST_PRODUCTS: 'wishlist/remove_product_from_wishlist_products',
  UPDATE_SHOW_ANIMATION_FOR_PRODUCTID: 'wishlist/update_show_animation_for_productid',
  RESET_WISHLIST_PRODUCTS_COUNT_AND_PAGES: 'wishlist/reset_wishlist_products_count_and_pages',
  UPDATE_PRODUCT_SKU_FROM_WISHLIST_STORE: 'wishlist/update_product_sku_from_wishlist_store',
  FETCH_PRODUCT_DATA: 'wishlist/fetch_product_data',
  FETCH_PDP_WISHLIST_ERROR: 'wishlist/fetch_pdp_wishlist_error',
};

export const setWishlistIds = (data: string[]) => ({
  type: ActionTypes.SET_WISHLIST_IDS,
  payload: data,
});

export const addProductIdToWishlist = (productId: string) => ({
  type: ActionTypes.ADD_PRODUCT_ID_TO_WISHLIST,
  payload: productId,
});

export const removeProductIdFromWishlist = (productId: string) => ({
  type: ActionTypes.REMOVE_PRODUCT_ID_FROM_WISHLIST,
  payload: productId,
});

export const addProductsToWishlistProducts = (
  products: Listing.IProduct[],
  productsCount: number,
  page: number
) => {
  return {
    type: ActionTypes.ADD_PRODUCTS_TO_WISHLIST_PRODUCTS,
    payload: { products, productsCount, page },
  };
};

export const addProductToWishlist = (product: Listing.IProduct, productsCount: number) => {
  return {
    type: ActionTypes.ADD_PRODUCT_TO_WISHLIST_PRODUCTS,
    payload: { product, productsCount },
  };
};

export const removeProductFromWishlistProducts = (productId: string) => ({
  type: ActionTypes.REMOVE_PRODUCT_FROM_WISHLIST_PRODUCTS,
  payload: productId,
});

export const updateShowAnimationForProductId = (productId: string) => ({
  type: ActionTypes.UPDATE_SHOW_ANIMATION_FOR_PRODUCTID,
  payload: productId,
});

export const resetWishlistTotalCountProductsAndPages = () => ({
  type: ActionTypes.RESET_WISHLIST_PRODUCTS_COUNT_AND_PAGES,
});

export const updateProductSKUFromWishlistStore = (
  productId: string,
  skuData: ProductDetail.ISizeOption
) => ({
  type: ActionTypes.UPDATE_PRODUCT_SKU_FROM_WISHLIST_STORE,
  payload: { productId, skuData },
});

export const fetchProductData = (productId: string) => ({
  type: ActionTypes.FETCH_PRODUCT_DATA,
  payload: productId,
});

export const setIsUpdating = (isUpdating: boolean) => ({
  type: ActionTypes.SET_IS_UPDATING,
  payload: isUpdating,
});
