// lib
import { SerializedStyles } from '@emotion/core';
import { EMAIL_REGEX } from './regex';
import get from 'lodash/get';

// constants
import { AKAMAI_IMAGE_URL, IMAGE_KIT_URL } from 'shared/constants';
import { IS_FILTERED_URL } from 'shared/layouts/Listing/Api/constants';

// helpers
import { getPLPFilterParams } from 'shared/helpers/utils/url';

export const validateEmail = (email: string): boolean => {
  return EMAIL_REGEX.test(String(email).toLowerCase());
};

const widthBucketConfig: number[] = [64, 128, 256, 512, 720, 900, 1080, 1536];

export const getGlobalThis = () => {
  // @ts-ignore
  // eslint-disable-next-line no-undef
  if (typeof globalThis !== 'undefined') return globalThis as Window;
  if (typeof self !== 'undefined') return self;
  if (typeof window !== 'undefined') return window;
  if (typeof global !== 'undefined') return global;
  // @ts-ignore
  if (typeof this !== 'undefined') return this;
  throw new Error('Unable to locate global `this`');
};

interface IImageOpts {
  height?: number;
  width?: number;
  pr?: boolean;
  q?: number;
  bl?: number;
  e?: string;
}

const widthBucket = (width: number) => {
  let finalWidth = widthBucketConfig[widthBucketConfig.length - 1];
  for (const widthValue of widthBucketConfig) {
    if (width <= widthValue) {
      finalWidth = widthValue;
      break;
    }
  }
  return finalWidth;
};

export const getImageOptions = ({ height, width, pr, q, bl, e }: IImageOpts) => {
  let modifications = `${height ? `h-${height},` : ''}${width ? `w-${widthBucket(width)},` : ''}${
    pr ? `pr-${pr},` : ''
  }${q ? `q-${q},` : ''}${bl ? `bl-${bl},` : ''}${e ? `e-${e},` : ''}`;
  modifications = modifications.replace(/(.*),/, '$1');
  return modifications.length > 0 ? `tr=${modifications}` : '';
};

export const getImageOptionsV1 = ({ height, width, pr, q, bl, e }: IImageOpts) => {
  const modifications = `${height ? `h-${height},` : ''}${width ? `w-${width},` : ''}${
    pr ? `pr-${pr},` : ''
  }${q ? `q-${q},` : ''}${bl ? `bl-${bl},` : ''}${e ? `e-${e},` : ''}`;
  return modifications.length > 0 ? `/tr:${modifications}` : '';
};

const akamaiRxp = new RegExp('^https://adn-static1.nykaa.com', 'i');
export const nykaaRxp = new RegExp(
  '^https://(www|qa|preprod|qa-integration|smoke).nykaafashion.com',
  'i'
);
export const imageHostRegExp = new RegExp(
  '^https://(images-static.nykaa.com|www.nykaafashion.com|qa.nykaafashion.com|preprod.nykaafashion.com|qa-integration.nykaafashion.com|adn-static1.nykaa.com/nykdesignstudio-images|adn-static1.nykaa.com/uploads)/',
  'i'
);

const imageHostPDPVideo = new RegExp('^https://d2ec6rrjtftdyi.cloudfront.net/', 'i');

const imageKitHostPDPVideo = `${IMAGE_KIT_URL}/fashion-nonprod-nf-catalog-pipeline`;

export const canAddAkamaiUrl = (url: string) =>
  akamaiRxp.test(url) === false &&
  nykaaRxp.test(url) === false &&
  imageHostPDPVideo.test(url) === false
    ? false
    : true;

export const generateSameHostUrl = (url: string) => {
  if (!nykaaRxp.test(url)) return url;

  return url.replace(nykaaRxp, '');
};

export const getOptimisedImageUrl = (
  imageId: string,
  options: IImageOpts = {},
  transformationType?: 'append' | 'overwrite'
) => {
  if (imageHostRegExp.test(imageId) === false) {
    return imageId;
  }

  if (imageId.includes('tr=')) {
    let [before, after] = [...imageId.split('tr=')];
    if (transformationType === 'append') return `${before}${getImageOptions(options)}${after}`;

    return `${before}${getImageOptions(options)}`;
  } else {
    if (imageId?.includes('?')) {
      return `${imageId}&${getImageOptions(options)}`;
    }
    return `${imageId}?${getImageOptions(options)}`;
  }
};

export const getAkamaiUrl = (imageId: string, options: IImageOpts = {}) => {
  if (canAddAkamaiUrl(imageId) === false) {
    return imageId;
  }

  if (imageHostRegExp.test(imageId)) {
    const imgId = imageId.replace(imageHostRegExp, '').replace(/tr:[^/]+\//i, '');
    return `${AKAMAI_IMAGE_URL}${getImageOptionsV1(options)}/${imgId}`;
  } else if (imageHostPDPVideo.test(imageId)) {
    const imgId = imageId.replace(imageHostPDPVideo, '').replace(/tr:[^/]+\//i, '');
    return `${imageKitHostPDPVideo}${getImageOptionsV1(options)}/${imgId}`;
  } else {
    return `${AKAMAI_IMAGE_URL}${getImageOptionsV1(options)}/${imageId}`;
  }
};

export const cssMerge = (
  baseCss: SerializedStyles,
  augmentCss?: SerializedStyles[] | SerializedStyles
) => {
  const cssProps = [baseCss];

  if (augmentCss) {
    Array.isArray(augmentCss)
      ? cssProps.push.apply(cssProps, augmentCss)
      : cssProps.push(augmentCss);
  }

  return cssProps;
};

export const setCookie = (
  cname: string,
  cvalue: string | number | boolean,
  days: number,
  domain?: string
) => {
  const d = new Date();
  d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + d.toUTCString();
  let Domain = '';
  if (domain && domain !== '') {
    Domain = ';Domain=' + domain;
  }
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/' + Domain;
};

const getCookieData = (name: string, decodedCookie: string) => {
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const getCookie = (cname: string) => {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  return getCookieData(name, decodedCookie);
};

export const getCookieForServer = (cname: string, cookieData = '') => {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(cookieData);
  return getCookieData(name, decodedCookie);
};

export const inPriceFormat = (amount: string | number = '') => Number(amount).toLocaleString();

export const isScriptLoaded = (src: string) =>
  document.querySelector('script[src="' + src + '"]') ? true : false;

export const getActionURLWithPLPFilters = (item: Listing.IBannerImage, key: string) => {
  const FilterData = get(item, 'filter_data', '');
  const sortData = get(item, 'sort', '');
  const URL = get(item, key, '');

  if (FilterData === '' && sortData === '') {
    return URL;
  }

  const params = new URLSearchParams();
  const JOIN_CHAR = URL.indexOf('?') > -1 ? '&' : '?';

  params.set('f', getPLPFilterParams(FilterData, sortData));

  params.set(IS_FILTERED_URL, 'true');

  return generateSameHostUrl(URL + JOIN_CHAR + params.toString());
};

export function getImageUrlSet(imageUrl: string, width: number) {
  const url = getAkamaiUrl(imageUrl, { width });
  const url2x = getAkamaiUrl(imageUrl, { width: width * 2 });
  return `${url} 1x, ${url2x} 2x`;
}
export const isProduction = process.env.API_HOST === 'https://www.nykaafashion.com';

export const isDevelopment =
  process.env.NODE_ENV === 'development' ||
  (process.env.NODE_ENV === 'production' && process.env.PRODUCTION_TESTING === 'true');

export const swAllowed =
  process.env.NODE_ENV === 'production' || process.env.WITH_SERVICE_WORKER === 'true';

const dummyEmailRegExp = /^[0-9]+@nykaafashion.com$/;
export const isDummyEmail = (email: string) => dummyEmailRegExp.test(email);

/*
  safari only recognises date string with / and not -
  use this function when doing date manipulations
*/
export const normalizeDate = (date: string) => {
  return new Date(date.replace(/-/g, '/'));
};

/**
 * string-to-kebab-case
 */
export const toKebabCase = (key: string) => {
  return key.replace(/\W+/g, '-').toLowerCase();
};

export const isIntegerId = (id: string | number) => /^\d+$/.test(`${id}`);

export const getISOStringDate = () => {
  return new Date().toISOString();
};

export const isDateValid = (epochTime: number) => epochTime * 1000 > new Date();
