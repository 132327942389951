export const ActionTypes = {
  SET_SKU_DATA: 'details/set_data',
  FETCH_SKU_DATA_PROGRESS: 'detail/fetch_sku_data_progress',
  FETCH_SKU_DATA_SUCCESS: 'detail/fetch_sku_data_success',
  FETCH_SKU_DATA_NOT_FOUND: 'detail/fetch_sku_data_not_found',
  FETCH_SKU_DATA_FAILED: 'detail/fetch_sku_data_failed',
  FETCH_A_PLUS_DATA_PROGRESS: 'detail/fetch_a_plus_content_in_progress',
  FETCH_A_PLUS_DATA_EMPTY_RESPONSE: 'detail/fetch_a_plus_content_empty_response',
  FETCH_A_PLUS_DATA_SUCCESS: 'detail/fetch_a_plus_content_in_success',
  FETCH_A_PLUS_DATA_FAILED: 'detail/fetch_a_plus_content_in_failed',
  FETCH_EXPLORE_DATA_PROGRESS: 'detail/fetch_explore_data_progress',
  FETCH_EXPLORE_DATA_SUCCESS: 'detail/fetch_explore_data_success',
  FETCH_EXPLORE_DATA_FAILED: 'detail/fetch_explore_data_failed',
  FETCH_ALL_REVIEWS: 'detail/fetch_all_reviews',
  UPDATE_ALL_REVIEWS_DATA: 'detail/update_all_reviews_data',
  FETCH_ALL_REVIEWS_FAILED: 'detail/fetch_all_reviews_failed',
  CLEAR_ALL_REVIEWS: 'detail/clear_all_reviews_data',
  FETCH_ALL_IMAGES_FOR_REVIEWS: 'detail/fetch_all_images_for_reviews',
  FETCH_ALL_IMAGES_FOR_REVIEWS_FAILED: 'detail/fetch_all_images_for_reviews_failed',
  UPDATE_ALL_IMAGES_FOR_REVIEWS: 'detail/update_all_images_for_reviews',
  SET_IS_LIKED_BY_USER: 'detail/reviews/data/set_is_liked_by_user',
  SET_SELECTED_SKU_ID: 'detail/set_selected_sku_id',
  RESET_SELECTED_SKU_ID: 'detail/reset_selected_sku_id',
  FETCH_ECOM_POLICY_DATA_PROGRESS: 'detail/fetch_ecom_policy_progress',
  FETCH_ECOM_POLICY_DATA_SUCCESS: 'detail/fetch_ecom_policy_success',
  FETCH_ECOM_POLICY_DATA_FAILED: 'detail/fetch_ecom_policy_failed',
  SET_CUSTOMISATION_SECTION_VALUE: `details/set_customisation_section_value`,
  FETCH_DELIVERY_OR_SHIPPING_DATE_SUCCESS: 'detail/fetch_delivery_or_shipping_date_success',
  RESET_ERROR_SKU: 'detail/reset_error_sku',
  SET_PRODUCT_OFFERS: 'detail/set_product_offers',
  SET_OFFERS_FAILURE: 'detail/set_offers_failure',
  FETCH_ESTIMATED_DELIVERY_DETAILS: 'detail/fetch_edd_success',
};

export const clearReviews = () => ({
  type: ActionTypes.CLEAR_ALL_REVIEWS,
});

export const setIsLikedByUser = (id: number, like: boolean) => ({
  type: ActionTypes.SET_IS_LIKED_BY_USER,
  payload: {
    id,
    like,
  },
});

export const setSelectedSKUID = (payload: { skuId: string; oneSize: boolean }) => {
  return {
    type: ActionTypes.SET_SELECTED_SKU_ID,
    payload,
  };
};

export const setCustomizationSectionValue = (payload: object) => ({
  type: ActionTypes.SET_CUSTOMISATION_SECTION_VALUE,
  payload,
});

export const resetSelectedSKUID = () => ({
  type: ActionTypes.RESET_SELECTED_SKU_ID,
});

export const resetErrorSku = () => ({
  type: ActionTypes.RESET_ERROR_SKU,
});

export const setProductOffers = (productOffers: object) => ({
  type: ActionTypes.SET_PRODUCT_OFFERS,
  payload: {
    productOffers,
  },
});

export const setOffersFailure = () => ({
  type: ActionTypes.SET_OFFERS_FAILURE,
});

export const setFetchEstimatedDeliveryDetails = (payload: object) => ({
  type: ActionTypes.FETCH_DELIVERY_OR_SHIPPING_DATE_SUCCESS,
  payload,
});
