import colors from '@nykaa/ui-components/tokens/colors';
import { hexToRgb } from '@nykaa/ui-components/styles/utils';

export const theme = {
  colors: {
    primary: colors.pebble900,
    primary10: colors.pebble100,
    primary20: colors.pebble200,
    primary30: colors.pebble400,
    primary40: colors.pebble600,
    primary50: colors.pebble700,
    surface: colors.snow100,
    surface10: colors.snow200,
    surface20: colors.snow300,
    surface30: colors.snow500,
    surface40: colors.snow600,
    surface50: colors.snow900,
    secondary: colors.salmon500,
    secondary10: colors.salmon50,
    secondary20: colors.salmon100,
    secondary30: colors.salmon400,
    secondary40: colors.salmon600,
    secondary50: colors.salmon700,
    inverse: colors.black100,
    inverse10: colors.black700,
    inverse20: colors.black600,
    inverse30: colors.black500,
    inverse40: colors.black400,
    inverse50: colors.black300,
    info: colors.blue600,
    // Note: value of blue50 is "#e6f2ff" rather than #E7F2FF
    info10: colors.blue50,
    stateHover: hexToRgb(colors.snow600, 0.08),
    statePressed: hexToRgb(colors.snow600, 0.16),
    stateDisabled: hexToRgb(colors.snow600, 0.36),
    textPrimary: colors.pebble800,
    surfaceInverse: colors.black100,
    surfaceInverse10: colors.black600,
    surfaceInverse20: colors.black500,
    surfaceInverse30: colors.black400,
    surfaceInverse40: colors.black300,
    surfaceInverse50: colors.black200,
    state: colors.snow600,
    menuItemHover: colors.salmon500,
    viewBagColor: colors.salmon500,
  },
  borders: {},
  typography: {},
};
