import { css } from '@emotion/core';

export const BASE_GUTTER_SPACE = 8;
export const BASE_BORDER_RADIUS = 8;
export const HEADER_Z_INDEX = 11;
export const FONT_SIZES = {
  BASE: '16px',
  H1: '28px',
  H2: '24px',
  H3: '20px',
  H4: '16px',
  H5: '14px',
};

// Fonts
export const FONT_THIN = 300;
export const FONT_MID = 500;
export const FONT_BOLD = 800;

export const FONTS = {
  INTER: 'Inter',
  BASE_FAMILY: `Inter, "Helvetica Neue", Helvetica, Arial, sans-serif`,
};
export const BASE_FONT = `${FONTS.INTER}, "Helvetica Neue", Helvetica, Arial, sans-serif`;

// Colors
export const BOX_SHADOW_COLOR = '#ccc';
export const BG_SECONDARY = `#d8d8d8`;
export const BG_GREY = '#f6f7f9';
export const TEXT_PRIMARY = `#000`;
export const TEXT_SECONDARY = `#9b9b9b`;
export const TEXT_SECONDARY_64 = `#bfbfbf`;
export const TEXT_TERTIARY = `#4a4a4a`;
export const TAG_DEFAULT_COLOR = '#ebf4ff';
export const BANNER_SHADOW = '2px 3px 0 rgba(0, 0, 0, 0.1)';
export const BANNER_SHADOW_HOVER = '0 2px 12px 0 rgba(0, 0, 0, 0.3)';
export const BG_BUTTON_OVERLAY = '#cdcdcd';

export const DWEB_HEADER_HEIGHT = 85;
export const DWEB_DISCOUNT_HEIGHT = 24;
export const DWEB_PRIMARY_HEADER_HEIGHT = 64;
export const DWEB_SECONDARY_HEADER_HEIGHT = 38;
export const PROMO_BANNER_HEIGHT = 24;

export const MWEB_HEADER_HEIGHT = 56;
export const MWEB_HEADER_DISCOUNT_HEIGHT = 75;
export const DWEB_MAX_WIDTH = 1200;

export const LISTING_HEADER_HEIGHT = 56;
export const MWEB_HEADER_ZINDEX = 10;
export const OVERLAY_ZINDEX = 1000;
export const NUDGE_Z_INDEX = 1002;

export const MWEB_HEADER_ICON_WIDTH = 40;

export const MOBILE_NAVIGATION_HEIGHT = 56;

export const MWEB_FILERS_CONTAINTER_HEIGHT = 40;

export const MWEB_WISHLIST_HEADER_HEIGHT = 48;

export const MWEB_COLLAPSIBLE_FOOTER_HEIGHT = 64;
export const FOOTER_HEIGHT = 44;

export const LISTING_GUTTER_SPACE_MWEB = 1;

export const BOTTOM_NAV_HEIGHT = 56;

export const MAX_DWEB_WIDTH = 1440;

export const COLORS = {
  PLACEHOLDER: '#f4f4f4',
  BLACK_150: '#000f1d',
  BLACK_100: '#001325',
  BLACK_96: '#111314',
  BLACK_50: '#3f414d',
  BLACK_92: '#142536',
  BLACK_84: '#282b2c',
  BLACK_64: '#5c6874',
  BLACK_36: '#a3aab0',
  BLACK_24: '#c2c7cb',
  BLACK_16: '#d6d9dc',
  BLACK_8: '#ebedee',
  BLACK_4: '#f3f4f5',
  BLACK_2: '#ebebeb',
  BRAND_PRIMARY_100: '#fd7685',
  GREEN: '#008945',
  INFO: '#006DFF',
  GRAY: '#cbcccd',
  LIGHT_GRAY: '#eee',
  GRAY_CROSS: '#cccccc',
  LIGHT_GREEN: '#EBF6F1',
  ALERT: {
    DANGER: '#B60000',
    INFO: '#006DFF',
    SUCCESS: '#008945',
    WARNING: '#DE3800',
  },
  WHITE_100: '#fff',
  LIGHT_PINK: '#FFD9E2',
  DARK_GRAY: '#38393a',
  DS_GREY_100: '#657786',
  DS_GREY_8: '#65778614',
  DS_GREY_16: '#65778629',
};

export const FIT_ADVISOR_COLORS = {
  BUTTON_PRIMARY: '#ca606a',
  PROFILE_BACKGROUND: '#e3c2c2',
  PROFILE_BACKGROUND_58: '#efdcdc',
};

export const OVERLAY_CSS = css`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: ${OVERLAY_ZINDEX};
  background-color: rgba(0, 0, 0, 0.5);

  // disable scroll based listener for overlay container,
  // in some cases, scroll is initiated randomly owing to browser behaviours or multiple overlays
  touch-action: none;
`;

export const HIDE_SCROLLBARS = css`
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
    width: 0 !important;
    background: transparent;
  }
`;

export const HORIZONTAL_SCROLLBARS = css`
  scrollbar-width: thin;
  scrollbar-gutter: inherit;

  &::-webkit-scrollbar {
    height: 4px !important;
  }

  &::-webkit-scrollbar-thumb {
    background: ${COLORS.BLACK_36};
    border-radius: 2px;
  }
`;

export const APP_CONTAINER_CSS = css`
  margin-bottom: ${BOTTOM_NAV_HEIGHT}px;
`;

export const BREAKPOINTS = {
  DESKTOP: 1100,
  TABLET_BIG: 1024,
  TABLET: 768,
  MOBILE: 414,
};

export const MEDIA_BREAKPOINTS = {
  TABLET_BIG: `@media (min-width: ${BREAKPOINTS.TABLET_BIG + 1}px) and (max-width: ${
    BREAKPOINTS.DESKTOP
  }px)`,
  TABLET: `@media (min-width: ${BREAKPOINTS.TABLET}px) and (max-width: ${BREAKPOINTS.TABLET_BIG}px)`,
  TILL_DESKTOP: `@media (max-width: ${BREAKPOINTS.DESKTOP}px)`,
  TILL_TABLET_BIG: `@media (max-width: ${BREAKPOINTS.TABLET_BIG}px)`,
  TILL_TABLET: `@media (max-width: ${BREAKPOINTS.TABLET}px)`,
  MOBILE: `@media (max-width: ${BREAKPOINTS.MOBILE}px)`,
};

// After this breakpoint the text would be removed,
// only icons would be visisble
export const DWEB_ICON_BREAKPOINT = '1354px';

// theme for deals platform driven landing pages
export const themeDealsPlatform = {
  brandPrimary: COLORS.BLACK_100,
  brandSecondary: COLORS.BLACK_64,
  darkGrey: COLORS.GRAY,
  lightGrey: COLORS.LIGHT_GRAY,
  darkGreen: COLORS.GREEN,
  lightOrange: '#fa6400',
  brickColor: '#E02020',
  lightBorder: BOX_SHADOW_COLOR,
  lightBlack: COLORS.BLACK_8,
  fontFamily: FONTS.BASE_FAMILY,
};

//using convention with SPACE not width because it contains gutter spaces as well.
export const PRODUCT_IMAGE_CONTAINER_SPACE = 630;

export const LOADER_ZINDEX = 10000;
