export default JSON.stringify({
  '@context': 'https://schema.org',
  '@type': 'WebSite',
  url: 'https://www.footlocker.co.in/',
  potentialAction: [
    {
      '@type': 'SearchAction',
      target: {
        '@type': 'EntryPoint',
        urlTemplate: 'https://www.footlocker.co.in/search?q={search_term_string}',
      },
      'query-input': 'required name=search_term_string',
    },
  ],
});
