// searchable value for tags
export const ERROR_TAGS = {
  DEFAULT: 'UI_FATAL_ERROR',
  API: 'API_ERROR',
  FIREBASE_CONFIG_FAILURE: 'FIREBASE_CONFIG_FAILURE',
  SERVICE_WORKER: 'SERVICE_WORKER',
  API_EXPERIMENT: 'API_EXPERIMENT',
  AB_EXPERIMENT: 'AB_EXPERIMENT',
  TRANFORMER: 'TRANSFORMER',
  ANALYTICS: 'ANALYTICS',
  API_RESOLUTION: 'API_RESOLUTION',
  MY_PROFILE: 'MY_PROFILE',
  MANDATORY_PARAM_MISSING: 'MANDATORY_PARAM_MISSING',
  GUEST_LOGIN: 'GUEST_LOGIN',
  PLP_IMAGE_SWIPED: 'PLP_IMAGE_SWIPED',
};

// tags required to segregate errors in sentry
export const TAG_IDENTIFIER = {
  ERROR: 'ERROR_TYPE',
  USER: 'id',
  MODULE: 'MODULE',
  APP_TYPE: 'APP_TYPE',
  HOSTNAME: 'HOSTNAME',
  PATHNAME: 'PATHNAME',
  STATUS_CODE: 'STATUS_CODE',
};
