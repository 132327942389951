import { produce } from 'immer';
import { ActionTypes } from './actions';
import { IAction, WidgetState } from './types';

export const initialState = Object.freeze<WidgetState>({
  widgetData: {},
  isFetching: false,
  isFetchingError: false,
  productID: '',
  isStarted: false,
});

export default (state: WidgetState = initialState, action: IAction): WidgetState =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case ActionTypes.FETCH_WIDGET_DATA_PROGRESS: {
        draft.isFetching = true;
        draft.isStarted = true;
        draft.productID = payload;
        return;
      }

      case ActionTypes.FETCH_WIDGET_DATA_SUCCESS: {
        draft.isFetching = false;
        draft.isFetchingError = false;
        draft.widgetData = payload.response;
        draft.productID = payload.productID;
        return;
      }

      case ActionTypes.FETCH_WIDGET_DATA_FAILED: {
        draft.isFetching = false;
        draft.isFetchingError = true;
        draft.productID = '';
        return;
      }
    }
  });
