export const ActionTypes = {
  FETCH_ADPLATFORM_TOPBANNER_SUCCESS: 'topbanner_fetch_adplatform_success',
  FETCH_ADPLATFORM_TOPBANNER_FAILED: 'topbanner_fetch_adplatform_failed',
  FETCH_ADPLATFORM_TOPBANNER_NOT_FOUND: 'topbanner_fetch_adplatform_not_found',
  FETCH_ADPLATFORM_TOPBANNER_CLEAR: 'topbanner_fetch_adplatform_clear',
};

export const notFound = () => ({
  type: ActionTypes.FETCH_ADPLATFORM_TOPBANNER_NOT_FOUND,
});
