// libs
import React from 'react';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter as Router } from 'connected-react-router';
import { Frontload } from 'react-frontload';
import { loadableReady } from '@loadable/component';

// store
import { configureStore } from 'shared/store';
import createHistory from 'shared/store/history';

// components
import App from 'shared/App';
import { browserResponsiveMode } from 'shared/components/ResponsiveLoader';

// helpers
import polyfillPromise from 'shared/helpers/client-polyfill';
import { swAllowed, isDevelopment } from 'shared/helpers/utils';
import { setGlobals } from 'shared/helpers/globals';
import { log } from 'shared/helpers/newRelic';

// constants
import { TAG_IDENTIFIER, ERROR_TAGS } from 'shared/helpers/SentryLogger/constants';

const history = createHistory();

// Create/use the store
// history MUST be passed here if you want syncing between server on initial route

const preloadStateEl = document.getElementById('__PRELOADED_STATE__');
const appDataEl = document.getElementById('__APP_DATA__');

const initialState = preloadStateEl ? JSON.parse(preloadStateEl.textContent || '{}') : {};
const appData = appDataEl ? JSON.parse(appDataEl.textContent || '{}') : {};

const store =
  window.store ||
  configureStore({
    initialState,
    history,
  });

// set all the values that should be present on client before render
setGlobals(initialState);

polyfillPromise.then(() => {
  loadableReady(() => {
    const { deviceWidth: browserWidth } = browserResponsiveMode();
    const deviceWidth = appData.ssrDeviceWidth || browserWidth;

    hydrate(
      <Provider store={store}>
        <Router history={history}>
          <Frontload noServerRender={true}>
            <App deviceWidth={deviceWidth} />
          </Frontload>
        </Router>
      </Provider>,
      document.getElementById('app')
    );
  });
});

if (isDevelopment) {
  if (module.hot) {
    module.hot.accept();
  }

  if (!window.store) {
    window.store = store;
  }
}

// Check that service workers are supported
if ('serviceWorker' in navigator && swAllowed) {
  // Use the window load event to keep the page load performant
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js').catch((err: any) => {
      log({
        errTitle: 'Error in service worker',
        err: err,
        tags: { [TAG_IDENTIFIER.MODULE]: ERROR_TAGS.SERVICE_WORKER },
      });
    });
  });
}
